import { FC, useEffect, useState } from 'react';

import Card from 'react-bootstrap/Card';
import './styles/styles.css';
import { Trash, Pencil } from 'react-bootstrap-icons';

import {
  Col,
  Dropdown,
  DropdownButton,
  OverlayTrigger,
  Row,
  Tooltip,
} from 'react-bootstrap';
import { EarlyCheckInModal } from '../../Modals/PackagesModals/EarlyCheckInModal';
import { DeletePackageModal } from '../../Modals/PackagesModals/DeletePackageModal';
import { PackagesResponse } from '../../../modules/interfaces';

interface PackageCardProps {
  title: string;
  subtitle: string;
  values?: PackagesResponse;
  imageUrl?: string;
}

export const PackageCard: FC<PackageCardProps> = ({
  title,
  subtitle,
  values,
  imageUrl,
}) => {
  const [showEarlyCheckModal, setShowEarlyCheckModal] = useState(false);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [packageId, setPackageId] = useState('');

  useEffect(() => {
    if (!values?.uuid) return;

    setPackageId(values?.uuid);
  }, [values]);

  return (
    <Card className="package-card">
      <Row>
        <Col style={{ display: 'flex', justifyContent: 'end' }}>
          <OverlayTrigger overlay={<Tooltip>Actions</Tooltip>}>
            <DropdownButton
              title=""
              className="page-dropdown package-actions package-actions-badge"
            >
              <Dropdown.Item
                style={{
                  fontSize: '14px',
                }}
                onClick={() => setShowEarlyCheckModal(true)}
              >
                <Pencil size={15} className="dropdown-icon" />
                Edit
              </Dropdown.Item>
              <Dropdown.Item
                style={{
                  color: 'red',
                  fontSize: '14px',
                }}
                onClick={() => setShowDeleteModal(true)}
              >
                <Trash size={15} className="dropdown-icon" />
                Delete
              </Dropdown.Item>
            </DropdownButton>
          </OverlayTrigger>
        </Col>
      </Row>
      <Card.Img
        variant="top"
        src={imageUrl}
        className="d-inline-block align-top package-card-image"
      />
      <Card.Body style={{ marginTop: '8px', padding: '0px 5px' }}>
        <span className="package-subtitle" style={{ marginLeft: '13px' }}>
          {subtitle}
        </span>
        <br />

        {title.length < 22 ? (
          <span className="package-title" style={{ marginLeft: '13px' }}>
            {title}
          </span>
        ) : (
          <OverlayTrigger overlay={<Tooltip>{title}</Tooltip>}>
            <span className="package-title" style={{ marginLeft: '13px' }}>
              {title.substring(0, 22)} {' ...'}
            </span>
          </OverlayTrigger>
        )}
      </Card.Body>

      <EarlyCheckInModal
        show={showEarlyCheckModal}
        handleClose={() => {
          setShowEarlyCheckModal(false);
        }}
        values={values || {}}
        isNew={false}
      />
      <DeletePackageModal
        show={showDeleteModal}
        handleClose={() => {
          setShowDeleteModal(false);
        }}
        packageId={packageId}
      />
    </Card>
  );
};
