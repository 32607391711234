import { FC } from 'react';
import { Col, Row } from 'react-bootstrap';
import { FileUploader } from 'react-drag-drop-files';
import UploadFileIcon from '../../../assets/upload-file.svg';

import { PreviewItem } from '../../Customize/PreviewItem';
import { FileStore } from '../PostEditor';

export interface BasicFileUploaderProps {
  files: FileStore[];
  fileLs?: FileList | null;

  handleDeleteFile: (fileUrl: string, uuid: string | undefined) => void;
  handleFileChange: (fileList: FileList) => void;
}

export const BasicFileUploader: FC<BasicFileUploaderProps> = ({
  files,
  fileLs,
  handleDeleteFile,
  handleFileChange,
}) => {
  const fileTypes = ['JPEG', 'PNG', 'GIF'];

  return (
    <>
      <FileUploader
        handleChange={handleFileChange}
        name="file"
        types={fileTypes}
        multiple
        maxSize={50}
        fileOrFiles={fileLs}
      >
        <div className="media-file-upload-container">
          <img src={UploadFileIcon} alt="upload-file" />
          <div className="file-upload-title">Upload file or drag & drop</div>
          <div className="file-upload-desc">PNG, JPG, GIF, MP4, MOV up to 50 MB</div>
        </div>
      </FileUploader>

      {files.length > 0 && (
        <Col sm={12} className="preview-section">
          <Row>
            {files.map((item, index) => {
              return (
                <PreviewItem
                  url={item.url}
                  key={item.url}
                  fileId={item.url}
                  uuid={item.id}
                  onDelete={handleDeleteFile}
                />
              );
            })}
          </Row>
        </Col>
      )}
    </>
  );
};
