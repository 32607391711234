import React, { ChangeEvent, FC, useCallback, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import 'react-datepicker/dist/react-datepicker.css';
import DropDown from '../../../assets/down-black.svg';
import EyeShow from '../../../assets/black-eye-show.svg';
import EyeHide from '../../../assets/black-eye-hide.svg';

import { Users } from '../../../modules/interfaces';
import './styles/styles.css';

type AddUsersProp = {
  show: boolean;
  onHide: () => void;
  onSave: (users?: string) => void;
  users: Users | undefined;
  isEditUsers?: boolean;
  isResetPassword?: boolean;
};

type CustomToggleProp = {
  children: React.ReactNode;
  onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

const CustomToggle = React.forwardRef(
  ({ children, onClick }: CustomToggleProp, ref: React.ForwardedRef<HTMLDivElement>) => (
    <div
      ref={ref}
      className="damage-report-drop-down-style"
      onClick={e => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      <img src={DropDown} alt="drop-down" />
    </div>
  ),
);

const AddUsers: FC<AddUsersProp> = ({
  show,
  onHide,
  onSave,
  users,
  isEditUsers,
  isResetPassword,
}) => {
  const roleList = ['Administrator', 'Regional Manager', 'Super admin', 'Normal user'];
  const [categoryText, setCategoryText] = useState('');
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [password, setPassword] = useState('');
  const [isPassShow, setIsPassShow] = useState(false);
  const [isConfirmPassShow, setsConfirmPassShow] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('Administrator');

  const onNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFirstname(event.target.value);
  };
  const onLastnameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setLastname(event.target.value);
  };
  const onEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };
  const onPasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };
  const onConfirmPasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(event.target.value);
  };
  const onSelectRole = (eventKey: string | null) => {
    if (eventKey) {
      setRole(eventKey);
    }
  };
  const onPasswordToggle = useCallback(() => {
    setIsPassShow(!isPassShow);
  }, [isPassShow]);

  const onConfirmPasswordToggle = useCallback(() => {
    setsConfirmPassShow(!isConfirmPassShow);
  }, [isConfirmPassShow]);

  const getColorFromStatus = (statusColor: string) => {
    if (statusColor === 'Administrator') {
      return '#2C49DF';
    }
    if (statusColor === 'Regional Manager') {
      return '#BF7927';
    }
    if (statusColor === 'Super admin') {
      return '#D04BDC';
    }
    return '#6B7280';
  };

  const GetHeaderName = () => {
    if (isResetPassword) {
      return 'Reset password';
    }
    if (isEditUsers) {
      return 'Edit user';
    }
    return 'Add new user';
  };
  return (
    <Modal
      className="modal-view-style"
      show={show}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title className="edit-guest-modal-title">{GetHeaderName()}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!isResetPassword && (
          <>
            <div className="user-name-text-view">First name</div>
            <input
              className="add-user-input-text"
              aria-describedby="noteHelp"
              placeholder="Enter user first name"
              onChange={onNameChange}
              value={firstname}
            />
            <div className="user-name-text-view">Last name</div>
            <input
              className="add-user-input-text"
              aria-describedby="noteHelp"
              placeholder="Enter user last name"
              onChange={onLastnameChange}
              value={lastname}
            />
            <div className="user-name-text-view">Email address</div>
            <input
              className="add-user-input-text"
              aria-describedby="noteHelp"
              placeholder="Enter email address"
              onChange={onEmailChange}
              value={email}
            />
            <div className="user-name-text-view">Role</div>
            <Dropdown onSelect={onSelectRole}>
              <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                <div className="row-status-drop-down">
                  <div
                    className="role-indicator"
                    style={{ backgroundColor: getColorFromStatus(role) }}
                  />
                  {role}
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu style={{ width: '100%' }}>
                {roleList.map(item => {
                  return (
                    <Dropdown.Item eventKey={item}>
                      <div
                        className="role-indicator"
                        style={{ backgroundColor: getColorFromStatus(item) }}
                      />
                      {item}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          </>
        )}
        {!isEditUsers && (
          <>
            {!isResetPassword && <div className="bottom-border-line" />}
            {!isResetPassword && (
              <div className="create-password-text">Create Password</div>
            )}
            <div className="user-name-text-view">
              {isResetPassword ? 'New Password' : 'Password'}
            </div>
            <div className="password-container">
              <input
                className="password-input-text"
                aria-describedby="noteHelp"
                placeholder="Create passwrod"
                onChange={onPasswordChange}
                value={password}
                type={isPassShow ? 'text' : 'password'}
              />
              <div onClick={onPasswordToggle}>
                <img src={isPassShow ? EyeHide : EyeShow} alt="eye-show" />
              </div>
            </div>
            <div className="user-name-text-view">
              {isResetPassword ? 'Confirm new password' : 'Confirm password'}
            </div>
            <div className="password-container">
              <input
                className="password-input-text"
                aria-describedby="noteHelp"
                placeholder="Confirm password"
                onChange={onConfirmPasswordChange}
                value={confirmPassword}
                type={isConfirmPassShow ? 'text' : 'password'}
              />
              <div onClick={onConfirmPasswordToggle}>
                <img src={isConfirmPassShow ? EyeHide : EyeShow} alt="eye-show" />
              </div>
            </div>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button className="edit-guest-modal-button" onClick={onHide}>
          Cancel
        </Button>
        <Button
          className="edit-guest-modal-button-save"
          onClick={() => onSave(categoryText)}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddUsers;
