import React, { FC, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Dropdown } from 'react-bootstrap';

import 'react-datepicker/dist/react-datepicker.css';
import './styles/styles.css';

import { useForm } from 'react-hook-form';
import { RefetchFunction } from 'axios-hooks';
import { ErrorMessage } from '@hookform/error-message';
import DropDown from '../../../assets/down-black.svg';
import { endpoints } from '../../../modules/mappers/urls';
import { useAuth, useNotifications, useRequest } from '../../../modules/hooks';
import { DamageReport, MewsReservationResponse } from '../../../modules/interfaces';
import { getApiErrorMessage } from '../../../modules/utils/transform';

type DamageReportmodalProp = {
  show: boolean;
  isEdit: boolean;
  onHide: () => void;
  reservationId: string;
  editData?: DamageReport;
  refetch?: RefetchFunction<unknown, unknown>;
};

type CustomToggleProp = {
  children: React.ReactNode;
  onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

const CustomToggle = React.forwardRef(
  ({ children, onClick }: CustomToggleProp, ref: React.ForwardedRef<HTMLDivElement>) => (
    <div
      ref={ref}
      className="damage-report-drop-down-style"
      onClick={e => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      <img src={DropDown} alt="drop-down" />
    </div>
  ),
);

const DamageReportmodal: FC<DamageReportmodalProp> = ({
  show,
  onHide,
  isEdit,
  reservationId,
  editData,
  refetch,
}) => {
  const damageList = ['Cause Damage', 'Chargeback Dispute', 'No-Show'];
  const statusList = ['Pending', 'Active', 'Resolved'];
  const { credentialsInfo } = useAuth()!;
  const { setSimpleToasts } = useNotifications()!;
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    trigger,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: 'Cause Damage',
      description: '',
      status: 'Pending',
    },
  });

  const damageName = watch('name');
  const damageStatus = watch('status');

  const getDamageReportUrl = `${endpoints.RESERVATION}/${reservationId}/damage-reports`;
  const [
    { data: insertResult, loading: loadingInsert, error: insertError },
    insertDamageReport,
  ] = useRequest<MewsReservationResponse>(
    getDamageReportUrl,
    'post',
    {
      authToken: credentialsInfo?.token,
    },
    { manual: true },
  );

  const getUpdateDamageReportUrl = `${endpoints.RESERVATION}/${reservationId}/damage-reports/${editData?.uuid}`;
  const [
    { data: updateResult, loading: loadingUpdate, error: updateError },
    updateDamageReport,
  ] = useRequest<MewsReservationResponse>(
    getUpdateDamageReportUrl,
    'put',
    {
      authToken: credentialsInfo?.token,
    },
    { manual: true },
  );

  const getColorFromStatus = (statusColor: string) => {
    if (statusColor === 'Pending') {
      return '#F19204';
    }
    if (statusColor === 'Active') {
      return '#0CA925';
    }
    return '#2651C1';
  };

  const onReportNameChange = (eventKey: string | null) => {
    if (eventKey) {
      setValue('name', eventKey);
    }
  };
  const onStateChange = (eventKey: string | null) => {
    if (eventKey) {
      setValue('status', eventKey);
    }
  };

  const createDamageReport = (roomData: DamageReport) => {
    const formData = new FormData();

    const { name, description, status } = roomData;

    formData.append('name', roomData.name || '');
    formData.append('description', roomData.description || '');
    formData.append('status', roomData.status || '');

    if (isEdit) {
      updateDamageReport({
        data: {
          name,
          description,
          status,
        },
      });
    } else {
      insertDamageReport({
        data: {
          name,
          description,
          status,
        },
      });
    }
  };

  const onSubmit = async (roomData: any) => {
    const validated = await trigger(['name', 'description', 'status']);

    if (validated) {
      createDamageReport(roomData);
    }
  };
  const onClose = () => {
    reset({
      name: 'Cause Damage',
      description: '',
      status: 'Pending',
    });
    onHide();
  };

  useEffect(() => {
    if (insertResult || updateResult) {
      if (refetch) {
        refetch();
      }
      onClose();
    }

    if (insertError) {
      const message = getApiErrorMessage(insertError);
      setSimpleToasts({ type: 'danger', message, show: true });
    }
    if (updateError) {
      const message = getApiErrorMessage(updateError);
      setSimpleToasts({ type: 'danger', message, show: true });
    }
  }, [insertResult, updateResult, insertError, updateError]);

  useEffect(() => {
    if (!editData || !show) return;

    reset({
      name: editData?.name || '',
      description: editData?.description || '',
      status: editData?.status || '',
    });
  }, [editData, show]);

  useEffect(() => {
    register('name', { required: 'This is required' });
    register('status', { required: 'This is required' });
  }, [register]);

  return (
    <Modal
      className="modal-view-style"
      show={show}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header>
          <Modal.Title className="edit-guest-modal-title">
            {isEdit ? 'Edit damage report' : 'Add new damage report'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="edit-guest-input-title">Report name</div>
          <Dropdown onSelect={onReportNameChange}>
            <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
              {damageName}
            </Dropdown.Toggle>
            <Dropdown.Menu style={{ width: '100%' }}>
              {damageList.map(item => {
                return (
                  <Dropdown.Item eventKey={item} active={item === damageName}>
                    {item}
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
          <ErrorMessage
            errors={errors}
            name="name"
            render={({ message }) => <span className="error-text">{message}</span>}
          />

          <div className="edit-guest-input-title">Description</div>
          <textarea
            className="description-text-input-style "
            aria-describedby="emailHelp"
            placeholder="Guest accidentally damaged a lamp during their stay"
            {...register('description')}
          />
          <ErrorMessage
            errors={errors}
            name="description"
            render={({ message }) => <span className="error-text">{message}</span>}
          />
          <div className="edit-guest-input-title">Report status</div>
          <Dropdown onSelect={onStateChange}>
            <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
              <div className="row-status-drop-down">
                <div
                  className="status-indicator"
                  style={{ backgroundColor: getColorFromStatus(damageStatus) }}
                />
                {damageStatus}
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu style={{ width: '100%' }}>
              {statusList.map(item => {
                return (
                  <Dropdown.Item eventKey={item}>
                    <div
                      className="status-indicator"
                      style={{ backgroundColor: getColorFromStatus(item) }}
                    />
                    {item}
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
          <ErrorMessage
            errors={errors}
            name="status"
            render={({ message }) => <span className="error-text">{message}</span>}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button className="edit-guest-modal-button" onClick={onClose}>
            Cancel
          </Button>
          <Button
            className="edit-guest-modal-button-save"
            type="submit"
            disabled={loadingInsert || loadingUpdate}
          >
            {loadingInsert || loadingUpdate ? 'Loading...' : 'Save'}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default DamageReportmodal;
