import { FC } from 'react';
import Modal from 'react-bootstrap/Modal';

import { Button, Container, Row } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';

import { Images } from 'react-bootstrap-icons';
import './styles/styles.css';
import Carousel from 'react-bootstrap/Carousel';

interface Media {
  name?: string;
  uri: string;
  thumbnailUri?: string;
  ext: string;
}

interface UserDetailsModalProps {
  show: boolean;
  handleClose: () => void;
  additionalImages: Media[];
}
interface paramsOptions {
  value: string;
  price: number;
  quantity: number;
  type: string;
}

export const CarouselModal: FC<UserDetailsModalProps> = ({
  show,
  handleClose,
  additionalImages,
}) => {
  return (
    <Modal
      className="modal-view-style"
      show={show}
      onHide={handleClose}
      backdrop="static"
      id="guest-portal"
    >
      <Modal.Header className="edit-guest-modal-title">
        <Modal.Title>
          <Images size={25} /> {'  '}
          Image Gallery
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          {additionalImages && additionalImages?.length > 0 ? (
            <Carousel>
              {additionalImages.map(item => (
                <Carousel.Item key={item.uri}>
                  <Image src={item?.uri} className="corousselImages" />
                </Carousel.Item>
              ))}
            </Carousel>
          ) : (
            <Image src="/no_image.jpg" className="corousselImages" />
          )}
        </Row>
        <Container
          style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}
        >
          <Button onClick={handleClose}>Cancel</Button>
        </Container>
      </Modal.Body>
    </Modal>
  );
};
