import React, {
  ChangeEvent,
  FC,
  KeyboardEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Col, Row } from 'react-bootstrap';
import { FileUploader } from 'react-drag-drop-files';
import ReactQuill from 'react-quill';

import 'react-quill/dist/quill.snow.css';
import 'react-datepicker/dist/react-datepicker.css';
import './styles/styles.css';
import { ErrorMessage } from '@hookform/error-message';
import Form from 'react-bootstrap/Form';
import { RefetchFunction } from 'axios-hooks';
import { useForm } from 'react-hook-form';

import { MultiSelect, Option } from 'react-multi-select-component';
import DropDownIcon from '../../../assets/down-black.svg';
import UploadFileIcon from '../../../assets/upload-file.svg';
import AddTagIcon from '../../../assets/add-tag-icon.svg';
import DeleteTagIcon from '../../../assets/delete-tag-icon.svg';
import {
  Article,
  ArticleRequest,
  ArticleResponse,
  Categories,
  SelectType,
} from '../../../modules/interfaces';
import { useAuth, useNotifications, useRequest } from '../../../modules/hooks';
import { endpoints } from '../../../modules/mappers/urls';
import { FileStore } from '../PostEditor';
import { PreviewItem } from '../../Customize/PreviewItem';
import { getApiErrorMessage } from '../../../modules/utils/transform';
import { BasicFileUploader } from '../Basic/FileUploader';

type AddArticlesProp = {
  show: boolean;
  onHide: () => void;
  onSave: () => void;
  refetch?: RefetchFunction<unknown, unknown>;
  categories: Categories[];
  article?: Article;
};

type CustomToggleProp = {
  children: React.ReactNode;
  onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

const CustomToggle = React.forwardRef(
  ({ children, onClick }: CustomToggleProp, ref: React.ForwardedRef<HTMLDivElement>) => (
    <div
      ref={ref}
      className="damage-report-drop-down-style"
      onClick={e => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      <img src={DropDownIcon} alt="drop-down" />
    </div>
  ),
);

const fileTypes = ['JPG', 'JPEG', 'PNG', 'GIF', 'MP4', 'MOV'];

type TagItem = {
  name: string;
  isAdd?: boolean;
  isEdit?: boolean;
};

const AddArticles: FC<AddArticlesProp> = ({
  show,
  onHide,
  onSave,
  refetch,
  categories,
  article,
}) => {
  const getTagList = (articleA: Article) => {
    const newArticle = articleA.tags.map(item => {
      return { name: item, isAdd: false };
    });
    newArticle.push({ name: '', isAdd: true });
    return newArticle;
  };

  const { credentialsInfo } = useAuth()!;
  const { setSimpleToasts } = useNotifications()!;

  const [files, setFiles] = useState<FileStore[]>([]);
  const [mediaDeleted, setMediaDeleted] = useState<string[]>([]);
  const [fileLs, setFileList] = useState<FileList | null>(null);
  const [tags, setTags] = useState<TagItem[]>([{ name: '', isAdd: true }]);

  const quill = useRef<ReactQuill | null>();

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    trigger,
    reset,

    formState: { errors },
  } = useForm({
    defaultValues: {
      name: '',
      category: [] as Option[],
      body: '',
      tags: [],
    },
  });

  const [
    { data: insertResult, loading: insertLoading, error: insertError },
    insertArticle,
  ] = useRequest<ArticleResponse>(
    endpoints.ARTICLES,
    'post',
    {
      authToken: credentialsInfo?.token,
    },
    { manual: true },
  );

  const updateUrl = `${endpoints.ARTICLES}/${article?.uuid}`;

  const [
    { data: updateResult, loading: updateLoading, error: updateError },
    updateArticle,
  ] = useRequest<ArticleResponse>(
    updateUrl,
    'patch',
    {
      authToken: credentialsInfo?.token,
    },
    { manual: true },
  );

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [
            {
              font: [],
            },
          ],
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
          [{ color: [] }, { background: [] }],
          [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
          [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
          ['link'],
          ['clean'],
        ],
      },
      clipboard: {
        matchVisual: true,
      },
    }),
    [],
  );

  const formats = [
    'bold',
    'italic',
    'underline',
    'strike',
    'indent',
    'blockquote',
    'list',
    'bullet',
    'align',
    'link',
    'color',
    'font',
    'header',
    'background',
    'clean',
  ];

  const getPropertyDropDownData = () => {
    const dropDownList: SelectType[] = [];

    categories.forEach(item => {
      dropDownList.push({
        value: item.uuid,
        label: item.name,
      });
    });
    return dropDownList;
  };

  const handleFileChange = useCallback(
    (fileList: FileList) => {
      Array.from(fileList).forEach(file => {
        const fileAdded = files.some(item => item.file.name === file.name);

        if (!fileAdded) {
          const fileUrl = URL.createObjectURL(file);
          setFiles([...files, { id: undefined, url: fileUrl, file }]);
        }
      });
    },
    [files],
  );

  const onAddNewTag = () => {
    const newTag = {
      name: '',
      isEdit: true,
    };
    const list = [...tags];
    list.unshift(newTag);
    setTags(list);
  };

  const onDeleteTag = (index: number) => {
    const list = [...tags];
    list.splice(index, 1);
    setTags(list);
  };

  const onSubmitTag = (index: number, event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && tags[index].name) {
      const newList = tags.map((item, j) => {
        const tagItem = { ...item };
        if (index === j) {
          tagItem.isEdit = false;
        }
        return tagItem;
      });
      setTags(newList);
    }
  };

  const onEditTagChange = (event: ChangeEvent<HTMLInputElement>, index: number) => {
    const newList = tags.map((item, j) => {
      const tagItem = { ...item };
      if (index === j) {
        tagItem.name = event.target.value;
      }
      return tagItem;
    });
    setTags(newList);
  };

  const handleTagClick = (e: React.MouseEvent<HTMLDivElement>, index: number) => {
    if (e.detail === 2) {
      const newList = tags.map((item, j) => {
        const tagItem = { ...item };
        if (index === j) {
          tagItem.isEdit = true;
        }
        return tagItem;
      });
      setTags(newList);
    }
  };

  const renderTagsItem = (item: TagItem, index: number) => {
    if (item.isAdd) {
      return (
        <div onClick={onAddNewTag} className="add-tags-text">
          <img src={AddTagIcon} alt="add-tag-icon" className="add-tag-icon" />
          Add tags
        </div>
      );
    }
    if (item.isEdit) {
      return (
        <div className="edit-tags-text">
          <input
            onChange={e => onEditTagChange(e, index)}
            value={item.name}
            onKeyDown={event => onSubmitTag(index, event)}
            placeholder="Tag name"
            className="p-0 edit-tag-input"
          />
          <div className="delete-tag-icon" onClick={() => onDeleteTag(index)}>
            <img src={DeleteTagIcon} alt="delete-tag-icon" />
          </div>
        </div>
      );
    }
    return (
      <div onClick={e => handleTagClick(e, index)} className="view-tags-text">
        {item.name}
        <div className="delete-tag-icon" onClick={() => onDeleteTag(index)}>
          <img src={DeleteTagIcon} alt="delete-tag-icon" />
        </div>
      </div>
    );
  };

  const handleDeleteFile = useCallback(
    (fileUrl: string, uuid: string | undefined) => {
      const filteredFiles = files.filter(item => item.url !== fileUrl);
      const list = new DataTransfer();

      URL.revokeObjectURL(fileUrl);

      filteredFiles.forEach(fileStore => list.items.add(fileStore.file));

      const { files: dtFiles } = list;

      if (uuid) {
        setMediaDeleted([...mediaDeleted, uuid]);
      }

      setFileList(dtFiles.length ? dtFiles : null);
      setFiles(filteredFiles);
    },
    [files, mediaDeleted],
  );

  const onBodyArticleChange = (editorState: string) => {
    setValue('body', editorState);
  };
  const onCategoryChange = (editorState: Option[]) => {
    setValue('category', editorState);
  };

  const editorContent = watch('body');
  const categoryMultiselect = watch('category');

  const saveProperty = (data: ArticleRequest) => {
    const formData = new FormData();

    formData.append('name', data.name || '');
    formData.append('body', data.body || '');
    if (data.category) {
      const categoriesIds = data.category.map((item: Option) => item.value);
      formData.append('category', categoriesIds.join(','));
    }

    if (tags) {
      const validTags = tags.filter(tag => tag.name !== '').map(tag => tag.name);

      formData.append('tags', validTags.join(','));
    }

    if (files) {
      const media = files.filter(file => file.file.name !== '').map(file => file.file);
      media.forEach(file => {
        const fileName = file.name;
        formData.append('files', file, fileName);
      });
    }

    if (!article) {
      insertArticle({
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
          authorization: credentialsInfo?.token,
        },
      });
    } else {
      if (mediaDeleted.length > 0) {
        formData.append('mediaDeleted', JSON.stringify(mediaDeleted));
      }
      updateArticle({
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
          authorization: credentialsInfo?.token,
        },
      });
    }
  };

  const onSubmit = async (data: any) => {
    const validated = await trigger(['name', 'body', 'category', 'tags']);

    if (validated) {
      saveProperty(data);
    }
  };

  const handleClose = () => {
    setFiles([]);
    setTags([{ name: '', isAdd: true }]);
    reset({
      name: '',
      category: [] as Option[],
      body: '',
      tags: [],
    });
    onHide();
  };

  useEffect(() => {
    if (insertResult || updateResult) {
      if (refetch) {
        refetch();
      }
      handleClose();
    }
    if (insertError) {
      const message = getApiErrorMessage(insertError);
      setSimpleToasts({ type: 'danger', message, show: true });
    }
    if (updateError) {
      const message = getApiErrorMessage(updateError);
      setSimpleToasts({ type: 'danger', message, show: true });
    }
  }, [insertResult, updateResult, insertError, updateError]);

  useEffect(() => {
    if (!article || !show) return;

    reset({
      name: article?.name || '',
      category: [] as Option[],
      body: article?.body || '',
      tags: [],
    });

    if (article?.categories) {
      const selectedCategories = article.categories.map(item => ({
        value: item.uuid,
        label: item.name,
      }));
      onCategoryChange(selectedCategories);
    }

    if (article?.media) {
      const mediaData = article?.media.map((item): FileStore => {
        return { id: item.uuid, url: item.uri, file: new File([], '') };
      });
      setFiles(mediaData);
    }

    if (article?.tags.length > 0) {
      setTags(getTagList(article));
    }
  }, [article, show]);

  useEffect(() => {
    register('body', { required: 'This is required' });
    register('category', { required: 'This is required' });
  }, [register]);

  return (
    <Modal
      className="modal-view-style"
      show={show}
      contentClassName="modal-content-style"
      dialogClassName="article-modal-classname"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header>
          <Modal.Title className="edit-guest-modal-title">
            {article ? 'Edit Article' : 'Create new article'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Label className="editor-title-label">Article name</Form.Label>
          <Form.Control
            className="editor-control"
            placeholder="Enter Article name"
            {...register('name', { required: 'This is required' })}
          />
          <ErrorMessage
            errors={errors}
            name="name"
            render={({ message }) => <span className="error-text">{message}</span>}
          />

          {categories && categories?.length !== 0 && (
            <Row className="mt-24px">
              <Form.Group className="border-bottom">
                <Form.Label className="editor-title-label">Category</Form.Label>
                <MultiSelect
                  options={getPropertyDropDownData()}
                  value={categoryMultiselect}
                  onChange={(values: Option[]) => {
                    onCategoryChange(values);
                  }}
                  labelledBy="Select"
                />
                <ErrorMessage
                  errors={errors}
                  name="category"
                  render={({ message }) => <span className="error-text">{message}</span>}
                />
              </Form.Group>
            </Row>
          )}

          <Form.Label className="mt-24px">Article Text</Form.Label>

          <ReactQuill
            theme="snow"
            ref={el => {
              quill.current = el;
            }}
            modules={modules}
            value={editorContent}
            onChange={onBodyArticleChange}
            formats={formats}
            placeholder="Enter Article text"
          />

          <Form.Label className="mt-14px">Media files</Form.Label>

          <BasicFileUploader
            files={files}
            fileLs={fileLs}
            handleDeleteFile={handleDeleteFile}
            handleFileChange={handleFileChange}
          />

          <Form.Label className="mt-24px">Tags</Form.Label>
          <div className="tag-list-style">{tags.map(renderTagsItem)}</div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="edit-guest-modal-button" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            type="submit"
            className="edit-guest-modal-button-save"
            disabled={insertLoading || updateLoading}
          >
            {insertLoading || updateLoading ? 'Loading…' : 'Save'}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default AddArticles;
