import { FC } from 'react';
import Modal from 'react-bootstrap/Modal';
import { PersonCheck } from 'react-bootstrap-icons';
import DeleteInfoIcon from '../../../assets/delete-info.svg';
import DeleteIcon from '../../../assets/red-delete.svg';
import SuspendBigIcon from '../../../assets/suspend-big.svg';

import 'react-datepicker/dist/react-datepicker.css';
import './styles/styles.css';

type DeleteModalProp = {
  show: boolean;
  onHide: () => void;
  title: string;
  description: string;
  onDelete: () => void;
  isUserDelete?: boolean;
  isUsersuspend?: boolean;
};

const DeleteModal: FC<DeleteModalProp> = ({
  show,
  onHide,
  onDelete,
  title,
  description,
  isUserDelete,
  isUsersuspend,
}) => {
  const getIcon = () => {
    if (isUsersuspend) {
      return SuspendBigIcon;
    }
    if (isUserDelete) {
      return DeleteIcon;
    }
    return DeleteInfoIcon;
  };

  const getButtonTitle = () => {
    if (isUsersuspend) {
      if (title === 'Do you want to activate this user?') {
        return 'Yes, activate';
      }
      return 'Yes, suspend';
    }

    return 'Delete';
  };

  return (
    <Modal
      className="modal-delete-view-style"
      show={show}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        {title === 'Do you want to activate this user?' ? (
          <PersonCheck size={50} />
        ) : (
          <img className="delete-info-image-style" src={getIcon()} alt="delete-info" />
        )}
        <div className="delete-info-text-style">{title}</div>
        <div className="delete-info-remove-text-style">{description}</div>
        <div className="delete-btn-container">
          <div className="delete-btn-cancal-style" onClick={onHide}>
            Cancel
          </div>
          <div className="delete-info-btn-detele-style" onClick={onDelete}>
            {getButtonTitle()}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DeleteModal;
