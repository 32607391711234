import React, { ChangeEvent, FC, useState } from 'react';
import { Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import 'react-datepicker/dist/react-datepicker.css';
import './styles/styles.css';
import { AddRoleRequest, Roles } from '../../../modules/interfaces';

type AddNewRoleProp = {
  show: boolean;
  isEditrole?: boolean;
  onHide: () => void;
  onSave: (addRoleRequest: AddRoleRequest) => void;
  actionRole: Roles | undefined;
};

const AddNewRole: FC<AddNewRoleProp> = ({
  show,
  onHide,
  onSave,
  isEditrole,
  actionRole,
}) => {
  const [rolename, setRoleName] = useState(actionRole?.name || '');
  const [viewPermission, setViewPermission] = useState(
    actionRole?.permissions.includes('view') || false,
  );
  const [editPermission, setEditPermission] = useState(
    actionRole?.permissions.includes('edit') || false,
  );
  const [deletePermission, setDeletePermission] = useState(
    actionRole?.permissions.includes('delete') || false,
  );
  const [createPermission, setCreatePermission] = useState(
    actionRole?.permissions.includes('create') || false,
  );
  const [managePermission, setManagePermission] = useState(
    actionRole?.permissions.includes('manage') || false,
  );

  const onRoleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRoleName(event.target.value);
  };

  const handleSwitchChange = () => {
    setViewPermission(!viewPermission);
  };
  const handleEditSwitchChange = () => {
    setEditPermission(!editPermission);
  };
  const handleDeletSwitchChange = () => {
    setDeletePermission(!deletePermission);
  };
  const handleCreateSwitchChange = () => {
    setCreatePermission(!createPermission);
  };
  const handleManageSwitchChange = () => {
    setManagePermission(!managePermission);
  };

  return (
    <Modal
      className="modal-view-style"
      show={show}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title className="edit-guest-modal-title">
          {isEditrole ? 'Edit role' : 'Add new role'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="user-name-text-view">Role name</div>
        <input
          className="add-user-input-text"
          aria-describedby="noteHelp"
          placeholder="Enter role name"
          onChange={onRoleChange}
          value={rolename}
        />
        <div className="permission-text">Permissions</div>
        <div className="swich-main-container">
          <div className="swich-text-style">View</div>
          <Form.Check
            type="switch"
            id="custom-switch"
            checked={viewPermission}
            onChange={handleSwitchChange}
            className="custom-switch"
          />
        </div>
        <div className="border-bottom-view" />
        <div className="swich-main-container">
          <div className="swich-text-style">Edit</div>
          <Form.Check
            type="switch"
            id="custom-switch"
            checked={editPermission}
            onChange={handleEditSwitchChange}
            className="custom-switch"
          />
        </div>
        <div className="border-bottom-view" />
        <div className="swich-main-container">
          <div className="swich-text-style">Delete</div>
          <Form.Check
            type="switch"
            id="custom-switch"
            checked={deletePermission}
            onChange={handleDeletSwitchChange}
            className="custom-switch"
          />
        </div>
        <div className="border-bottom-view" />
        <div className="swich-main-container">
          <div className="swich-text-style">Create</div>
          <Form.Check
            type="switch"
            id="custom-switch"
            checked={createPermission}
            onChange={handleCreateSwitchChange}
            className="custom-switch"
          />
        </div>
        <div className="border-bottom-view" />
        <div className="swich-main-container">
          <div className="swich-text-style">Manage</div>
          <Form.Check
            type="switch"
            id="custom-switch"
            checked={managePermission}
            onChange={handleManageSwitchChange}
            className="custom-switch"
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button className="edit-guest-modal-button" onClick={onHide}>
          Cancel
        </Button>
        <Button
          className="edit-guest-modal-button-save"
          onClick={() =>
            onSave({
              name: rolename,
              permissions: [
                {
                  permission: 'view',
                  value: viewPermission,
                },
                {
                  permission: 'edit',
                  value: editPermission,
                },
                {
                  permission: 'delete',
                  value: deletePermission,
                },
                {
                  permission: 'create',
                  value: createPermission,
                },
                {
                  permission: 'manage',
                  value: managePermission,
                },
              ],
            })
          }
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddNewRole;
