import { FC, useState, useRef } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import { ColDef, ICellRendererParams, RowClickedEvent } from 'ag-grid-community';
import { PersonCheck } from 'react-bootstrap-icons';
import { BadgeCellRenderer, DataTable } from '../DataTable';
import { config } from '../../../modules/config';
import { Users } from '../../../modules/interfaces';
import DeleteIcon from '../../../assets/delete-icon.svg';
import EditblackIcon from '../../../assets/edit-icon.svg';
import VerticalDotMenuIcon from '../../../assets/vertical-dot-menu.svg';
import ResetPassIcon from '../../../assets/reset-password.svg';
import SuspendIcon from '../../../assets/suspend.svg';
import {
  roleColorMapper,
  roleTextColorMapper,
} from '../../../modules/mappers/statusColorMapper';

type UsersTableProps = {
  refreshHandler: () => Promise<void>;
  searchString: string;
  tableData: Users[];
  onEditUser: (user?: Users) => void;
  onDeleteUser: (user?: Users) => void;
  onSuspendUser: (user?: Users) => void;
  onResetPassword: (user?: Users) => void;
};

export const UsersTable: FC<UsersTableProps> = ({
  refreshHandler,
  searchString,
  tableData,
  onEditUser,
  onDeleteUser,
  onSuspendUser,
  onResetPassword,
}: UsersTableProps) => {
  const gridRef = useRef<AgGridReact>(null);
  const navigate = useNavigate();

  const roleCellRenderer: FC<ICellRendererParams> = (params: ICellRendererParams) => {
    const { value, data } = params;
    return (
      <div
        className="role-main-container"
        style={{
          opacity: data.status === 'INACTIVE' || data.status === 'SUSPENDED' ? 0.5 : 1,
        }}
      >
        <div
          className="dot-view-style"
          style={{
            backgroundColor: roleColorMapper[value as string],
          }}
        />
        <div
          className="role-text-view"
          style={{
            color: roleTextColorMapper[value as string],
          }}
        >
          {value}
        </div>
      </div>
    );
  };

  const renderMenuIcon = (params: RowClickedEvent<Users>) => {
    return (
      <div className="menu-icon-container-style">
        <OverlayTrigger
          trigger="click"
          placement="left"
          rootClose
          overlay={
            <Popover id="popover-basic">
              <Popover.Body>
                <div
                  onClick={e => {
                    document.body.click();
                    onEditUser(params.data);
                  }}
                  style={{ paddingBottom: '8px' }}
                  className="edit-damage-report-container"
                >
                  <img
                    className="edit-icon-image-style"
                    src={EditblackIcon}
                    alt="edit-black-icon"
                  />
                  <div className="edit-text-style">Edit</div>
                </div>
                <div
                  onClick={e => {
                    document.body.click();
                    onResetPassword(params.data);
                  }}
                  style={{ paddingTop: '8px', paddingBottom: '8px' }}
                  className="edit-damage-report-container"
                >
                  <img
                    className="edit-icon-image-style"
                    src={ResetPassIcon}
                    alt="edit-black-icon"
                  />
                  <div className="edit-text-style">Reset password</div>
                </div>
                <div
                  onClick={e => {
                    document.body.click();
                    onSuspendUser(params.data);
                  }}
                  style={{ paddingTop: '8px', paddingBottom: '8px' }}
                  className="edit-damage-report-container"
                >
                  {params.data?.status !== 'SUSPENDED' ? (
                    <img
                      className="edit-icon-image-style"
                      src={SuspendIcon}
                      alt="edit-black-icon"
                    />
                  ) : (
                    <PersonCheck size={20} />
                  )}
                  <div className="edit-text-style">
                    {params.data?.status === 'SUSPENDED' ? 'Activate' : 'Suspend'}
                  </div>
                </div>
                <div
                  style={{ paddingTop: '8px' }}
                  className="edit-damage-report-container"
                  onClick={e => {
                    document.body.click();
                    onDeleteUser(params.data);
                  }}
                >
                  <img
                    className="edit-icon-image-style"
                    src={DeleteIcon}
                    alt="edit-black-icon"
                  />
                  <div className="delete-text-style">Delete</div>
                </div>
              </Popover.Body>
            </Popover>
          }
        >
          <div>
            <img src={VerticalDotMenuIcon} alt="vertical-icon" />
          </div>
        </OverlayTrigger>
      </div>
    );
  };

  const [columns] = useState<ColDef<Users>[]>([
    {
      field: 'firstName',
      headerName: 'FIRST NAME',
      flex: 1,
      cellStyle: params => ({
        fontSize: '13px',
        color:
          params.data?.status === 'INACTIVE' || params.data?.status === 'SUSPENDED'
            ? 'rgba(0, 0, 0, 0.5)'
            : '#000000',
      }),
    },
    {
      field: 'lastName',
      headerName: 'LAST NAME',
      flex: 1,
      cellStyle: params => ({
        fontSize: '13px',
        color:
          params.data?.status === 'INACTIVE' || params.data?.status === 'SUSPENDED'
            ? 'rgba(0, 0, 0, 0.5)'
            : '#000000',
      }),
      sortable: true,
    },
    {
      valueGetter: params => {
        const { data: user } = params;
        const role = user ? user.roles[0].name : '-';

        return role;
      },
      headerName: 'ROLE',
      flex: 1,
      sortable: true,
      cellRenderer: roleCellRenderer,
      cellStyle: params => ({
        color:
          params.data?.status === 'INACTIVE' || params.data?.status === 'SUSPENDED'
            ? 'rgba(0, 0, 0, 0.5)'
            : '#000000',
      }),
    },

    {
      headerName: 'EMAIL',
      sortable: true,
      field: 'email',
      cellStyle: { color: 'rgba(0, 0, 0, 0.5)' },
      flex: 2,
    },
    {
      field: 'status',
      headerName: 'STATUS',
      sortable: true,
      cellRenderer: BadgeCellRenderer,
      flex: 0.5,
    },
    {
      field: '',
      headerName: '',
      cellRenderer: renderMenuIcon,
      flex: 0.3,
    },
  ]);

  return (
    <DataTable
      refreshHandler={refreshHandler}
      searchString={searchString}
      gridRef={gridRef}
      changeState={0}
      rowData={tableData}
      columnDefs={columns}
      pagination
      paginationPageSize={config.tables.reservations.paginationSize}
    />
  );
};
