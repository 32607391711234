import { FC, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import 'react-datepicker/dist/react-datepicker.css';
import './styles/styles.css';

import ReservationPreferences from '../../ReservationPreferences';

type Pref = {
  title: string;
  isSelect: boolean;
};

type EditPreferencesProp = {
  show: boolean;
  onHide: () => void;
  dietaryPref: Array<Pref>;
  roomPref: Array<Pref>;
  onSave: (dietaryPref: Array<Pref>, roomPref: Array<Pref>) => void;
};

const EditPreferences: FC<EditPreferencesProp> = ({
  show,
  onHide,
  dietaryPref,
  roomPref,
  onSave,
}) => {
  const [dietaryPreferance, setDiataryPref] = useState(dietaryPref);
  const [roomPreferance, setRoomPref] = useState(roomPref);

  const onSelectPref = (title: string) => {
    setDiataryPref(prev => {
      const newPref = prev.map(_item => {
        const item = { ..._item };
        if (item.title === title) {
          item.isSelect = !item.isSelect;
        }
        return item;
      });
      return newPref;
    });
  };

  const onSelectRoomPref = (title: string) => {
    setRoomPref(prev => {
      const newPref = prev.map(_item => {
        const item = { ..._item };
        if (item.title === title) {
          item.isSelect = !item.isSelect;
        }
        return item;
      });
      return newPref;
    });
  };

  const onSavePref = () => {
    onSave(dietaryPreferance, roomPreferance);
  };

  useEffect(() => {
    setDiataryPref(dietaryPref);
    setRoomPref(roomPref);
  }, [dietaryPref, roomPref]);

  return (
    <Modal
      className="modal-view-style"
      show={show}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title className="edit-guest-modal-title">Edit preferences</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0">
        <ReservationPreferences
          isEditable
          dietaryPref={dietaryPreferance}
          roomPref={roomPreferance}
          onSelectPref={onSelectPref}
          onSelectRoomPref={onSelectRoomPref}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button className="edit-guest-modal-button" onClick={onHide}>
          Cancel
        </Button>
        <Button className="edit-guest-modal-button-save" onClick={onSavePref}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditPreferences;
