/* eslint-disable no-nested-ternary */
import { FC, ReactNode, useCallback } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Card from 'react-bootstrap/Card';
import { Image } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

interface ReservationCardProps {
  title: string;
  icon: ReactNode;
  imageUrl?: string;
  socialNetworkLink?: string;
}

const CardContent = (title: string, icon: ReactNode) => {
  return (
    <>
      {icon}
      <span style={{ marginLeft: '13px' }}> {title} </span>
    </>
  );
};

export const SocialNetworkCard: FC<ReservationCardProps> = ({
  title,
  icon,
  imageUrl,
  socialNetworkLink,
}) => {
  const redirectToLink = useCallback((link: string | undefined) => {
    if (link) window.open(link, '_blank');
  }, []);

  return (
    <Card
      onClick={() => {
        redirectToLink(socialNetworkLink);
      }}
      style={{ cursor: socialNetworkLink ? 'pointer' : '' }}
      className="icon-card"
    >
      <Card.Body>
        <Row>
          {imageUrl ? (
            <>
              <Col xs="9" className="card-title">
                {CardContent(title, icon)}
              </Col>
              <Col xs="3" style={{ display: 'flex', justifyContent: 'end' }}>
                <Image
                  alt=""
                  src={imageUrl}
                  width="60"
                  height="50"
                  style={{ marginTop: '0px' }}
                  className="ag-cell-img"
                />
              </Col>
            </>
          ) : (
            <Col xs="12" className="card-title">
              {CardContent(title, icon)}
            </Col>
          )}
        </Row>
      </Card.Body>
    </Card>
  );
};
