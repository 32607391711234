import { FC } from 'react';
import ToastContainer from 'react-bootstrap/ToastContainer';
import Toast from 'react-bootstrap/Toast';

import { useNotifications } from '../../../modules/hooks';

export const SimpleToasts: FC = () => {
  const { simpleToasts = { show: false }, setSimpleToasts } = useNotifications()!;
  const { message, type, show, delay } = simpleToasts;

  const toggleShow = () => setSimpleToasts({ show: !show });

  return (
    <ToastContainer position="top-center" className="position-fixed">
      <Toast delay={delay || 10000} show={show} bg={type} onClose={toggleShow} autohide>
        <Toast.Body>{message}</Toast.Body>
      </Toast>
    </ToastContainer>
  );
};
