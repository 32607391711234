export const numberSort = (num1: number, num2: number) => {
  return num1 - num2;
};

export const roomSort = (num1: string, num2: string) => {
  const number1 = num1 === 'Not assigned' ? 0 : parseInt(num1.split(' ')[0], 10);
  const number2 = num2 === 'Not assigned' ? 0 : parseInt(num2.split(' ')[0], 10);

  return number1 - number2;
};
