import React, { FC, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { Button, Col, Container, Row } from 'react-bootstrap';

import { FileText } from 'react-bootstrap-icons';
import SignatureCanvas from 'react-signature-canvas';
import './styles/styles.css';
import { useFormContext } from 'react-hook-form';

interface UserDetailsModalProps {
  show: boolean;
  handleClose: () => void;
  isEnd?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AgreementModal: FC<UserDetailsModalProps> = ({
  show,
  handleClose,
  isEnd,
}) => {
  const signCanvas = React.useRef() as React.MutableRefObject<SignatureCanvas>;
  const { setValue, getValues } = useFormContext();
  const [errorMessage, setErrorMessage] = useState('');

  const handleClear = () => {
    signCanvas.current.clear();
  };

  const onClickSubmit = () => {
    setValue('signature', signCanvas.current.toDataURL());

    if (signCanvas.current.isEmpty()) {
      setErrorMessage('Please enter your signature');
    } else {
      setErrorMessage('');
    }

    if (isEnd && !signCanvas.current.isEmpty()) {
      isEnd(true);
      handleClose();
    }
  };

  useEffect(() => {
    if (!getValues('signature')) return;
    getValues('signature');

    signCanvas.current.fromDataURL(getValues('signature'));
  }, [getValues, show]);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="lg"
      dialogClassName="post-editor-modal modal-200w"
      backdrop="static"
      id="guest-portal"
    >
      <Modal.Header className="guest-modal-header ">
        <Modal.Title className="editor-title">
          <FileText size={25} /> {'  '}
          Agreement
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="guest-modal-header">
        <Form>
          <Row>
            <Form.Text>
              <h6 className="text-white">OUR POLICIES</h6> <br />
              Deposit/Cancellation: For stays of any length, a deposit of 50% of the total
              charges is due at the time of booking. The second half of the deposit will
              be automatically deducted 7 days prior to your scheduled check-in. On the
              day of your arrival, a $100 security deposit will be held throughout the
              duration of your stay. Payment can be made via Visa, MasterCard, American
              Express, or Discover. We will send confirmation of your reservation upon
              receipt of your deposit.
              <br />
              <br />
              Cancellation policy is strict and will be enforced.
              <br />
              <br />
              Guests who cancel more than 30 days in advance will receive a refund of
              their initial deposit. Since we charge the remaining balance 7 days prior to
              check-in, guests will not be able to receive a full refund within 7 days of
              arrival. Refunds will not be honored for those who cancel within those time
              frames from their scheduled arrival date.
              <br />
              <br />
              Winter Storm Cancellations: All reservations for the months of December
              through February that need to be cancelled due to flight cancellations or a
              state of emergency will be given a 90-day grace period to reschedule their
              stay to a further date.
              <br />
              <br />
              <span>For any cancellations please contact:</span>
              <br />
              reservations@coachhousesalem.com
              <br />
              <br />
              <h6 className="text-white">During your stay:</h6> <br />
              There are absolutely no parties, gatherings, or events within the perimeters
              of The Coach House property. Any damage or stolen property will be charged
              to the card on file after written notice has been sent to the guest.
              <br />
              Check-in time is scheduled for 3:00 PM (EST) and check-out time is scheduled
              for 11:00 AM (EST).
              <br />
              There is no smoking and or vaping in the house. If you choose to do so,
              please smoke on the sidewalk outside of the building and remove any remnants
              into a nearby trash receptacle. If either a party is hosted or evidence of
              smoking in the house is found, you will be subject to a fine, and your
              reservation will be canceled immediately.
              <ul>
                <li>$500 for a party or gathering at the property</li>
                <li>$300 for smoking within the property</li>
              </ul>
              Guests who are not on the reservation will not be allowed on the property.
              <br />
              Although we love our four-legged friends, we do not allow pets to stay on
              The Coach House premises. <em>*Service animals are allowed.</em>
              <br />
              The Coach House is suitable for children 12 and older — we are unable to
              provide pack and plays or cribs.
              <br />
              Due to our property being historic, we do not have an elevator or chairlift.
              <br />
              <br />
              <h6 className="text-white">Damage/Theft:</h6> <br />
              You are liable for any damage caused (whether by deliberate, negligent, or
              reckless act) to the room(s) or property that is caused by you or any person
              in your party, whether or not staying at the hotel during your stay. The
              Coach House reserves the right to retain your credit card and/or debit card
              details, or forfeit your security deposit of $100.00. Should damage come to
              light after the guest has departed, we reserve the right, and you hereby
              authorize us, to charge your credit or debit card for any damage incurred to
              your room or the Hotel property during your stay, including and without
              limitation for all property damage, missing or damaged items, party fee,
              smoking fee, or a deep cleaning fee.
              <br />
              Damage Discovered After Check-out: Guest Rooms will be reviewed by our
              cleaning staff, as well as the Operations Manager. For any damage that is
              found, photos will be provided as evidence to the guest. In the event that
              The Coach House is unable to repair or fix damage, there will be an
              additional maintenance or deep cleaning fee (up to $150) that will be added
              to your account. Damage that would be subjected to an additional fee
              includes:
              <ul>
                <li>Broken/defaced furniture or decorative items</li>
                <li>Bodily/Bio-hazardous fluids found (vomit, feces, blood, etc.)</li>
                <li>Deeply stained linens or furniture</li>
                <li>
                  Damage to mattresses and bedding: Damage to mattresses and linen
                  including towels, mattress pads, sheets, bedspreads, blankets resulting
                  from the use of make-up, food, bodily fluids, etc., will result in a
                  replacement value charge plus any shipping and handling charges.
                </li>
              </ul>
              Damage to rooms, fixtures, furnishing, and equipment including the removal
              of electronic equipment, towels, artwork, etc., will be charged the total
              replacement value plus any shipping and handling charges. Any damage to
              hotel property, whether accidental or willful, is the responsibility of the
              registered guest for each particular room. Any costs associated with repairs
              and/or replacement will be charged to the credit card of the registered
              guest. In extreme cases, criminal charges will be pursued. For the common
              spaces, The Coach House does have and use camera surveillance; therefore, if
              any damage is caused, there will be video evidence.
              <br />
              <br />
              <h6 className="text-white">
                Damage or tampering with Fire Detection Systems/Fire-Fighting Equipment:
              </h6>
              <br />
              The Coach House reserves the right to take action against any guest or
              visitor found to have tampered or interfered with any detection equipment
              throughout the hotel, including detector heads in public areas, guest rooms,
              and fire extinguishers. Guests or visitors found to have tampered with any
              fire detection or fire-fighting equipment will be charged with any costs
              incurred by the hotel due to their actions and will be evicted and
              trespassed from the hotel. Depending on the severity of the guests&apos; law
              enforcement may become involved at the hotel&apos;s discretion. Should the
              fact that fire-fighting or detection equipment had been tampered with come
              to light after the guest has departed, we reserve the right and you hereby
              authorize us to charge your credit or debit card for any damage incurred.
              <br />
              <br />
              By reserving your stay with The Coach House, you are agreeing to abide by
              these rules. Claiming you were not aware is not an acceptable excuse. While
              accidents do happen, guests will be financially responsible for damages due
              to gross negligence.
            </Form.Text>
          </Row>
          <Row style={{ marginTop: '20px' }}>
            <Col
              sm={12}
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '16px',
                marginBottom: '16px',
              }}
            >
              <Button className="save-button no-border-radius" onClick={handleClear}>
                Clear
              </Button>
            </Col>
            <Col sm={12}>
              <div className="signature-canvas-container">
                <SignatureCanvas
                  canvasProps={{ className: 'signature-canvas' }}
                  ref={signCanvas}
                />
              </div>
            </Col>
            <Col
              sm={12}
              style={{ display: 'flex', justifyContent: 'flex-start', marginTop: '16px' }}
            >
              <span className="error-text">{errorMessage}</span>
            </Col>
          </Row>
        </Form>
      </Modal.Body>

      <Container className="guest-modal-footer ">
        <Button className="cancel-button no-border-radius" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          className="save-button no-border-radius"
          style={{ marginLeft: '10px' }}
          onClick={onClickSubmit}
        >
          Continue
        </Button>
      </Container>
    </Modal>
  );
};
