import { FC } from 'react';
import { Image } from 'react-bootstrap';
import { X } from 'react-bootstrap-icons';

import './styles/styles.css';

export interface PreviewItemProps {
  url?: string;
  fileId: string;
  uuid?: string;
  onDelete: (fileId: string, uuid: string | undefined) => void;
}

export const PreviewItem: FC<PreviewItemProps> = ({ url, uuid, fileId, onDelete }) => {
  return (
    <div className="attachment-item">
      <Image className="attachment-img" alt="" src={url} />
      <div className="attachment-delete" onClick={() => onDelete(fileId, uuid)}>
        <X size={16} />
      </div>
    </div>
  );
};
