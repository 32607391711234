import { FC, useState, useEffect, useRef } from 'react';
import Container from 'react-bootstrap/Container';
import { Button, Col, Overlay, Popover, Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { endpoints, routes } from '../../../../modules/mappers/urls';
import {
  useAuth,
  useLoading,
  useNotifications,
  useRequest,
} from '../../../../modules/hooks';
import { getApiErrorMessage } from '../../../../modules/utils/transform';
import {
  Article,
  ArticleResponse,
  BaseFilterRequest,
  Categories,
} from '../../../../modules/interfaces';
import './styles/styles.css';

import Header from '../../../Header';

import UnCheckIcon from '../../../../assets/uncheck-checkbox.svg';
import CheckIcon from '../../../../assets/check-checkbox.svg';

import AddArticles from '../../../Modals/AddArticles/AddArticles';
import DeleteModal from '../../../Modals/DeleteModal/DeleteModal';
import { ArticleCard } from '../../../Cards/ArticleCard/ArticleCard';

interface UpdateArticleState {
  articleId: string;
  status: string;
}

export const UserArticles: FC = () => {
  const { setSimpleToasts } = useNotifications()!;
  const [modal, setModal] = useState<boolean>(false);
  const [categories, setCategories] = useState<Categories[]>([]);
  const [recentData, setRecentData] = useState<Article[]>([]);
  const [articlesData, setArticlesData] = useState<Article[]>([]);
  const [articlesShown, setArticlesShown] = useState<number>(6);
  const [status, setStatus] = useState<string[]>([]);
  const [category, setCategory] = useState<string[]>([]);
  const [actionArticleState, setActionArticleState] = useState<
    UpdateArticleState | undefined
  >(undefined);
  const [filters, setFilters] = useState<BaseFilterRequest>({
    name: '',
    categories: [],
    status: ['Published'],
  });
  const [categoryShow, setCategoryShow] = useState(false);
  const categoryTarget = useRef(null);
  const [statusShow, setStatusShow] = useState(false);
  const [addArticle, setAddArticle] = useState(false);
  const [actionArticle, setActionArticle] = useState<Article | undefined>();
  const statusTarget = useRef(null);

  const location = useLocation();

  const { setLoading } = useLoading()!;
  const { credentialsInfo } = useAuth()!;

  const [{ data: categoriesData, loading: categoriesLoading, error: categoriesError }] =
    useRequest<Categories[]>(endpoints.CATEGORIES, 'get', {
      authToken: credentialsInfo?.token,
    });

  const [{ data, loading, error }, refetch] = useRequest<ArticleResponse>(
    endpoints.ARTICLES,
    'get',
    {
      authToken: credentialsInfo?.token,
      params: {
        name: filters.name,
        categories: filters.categories.length === 0 ? '' : filters.categories.join(','),
        status: filters.status.length === 0 ? '' : filters.status.join(','),
        fetchRecent: true,
        pageSize: articlesShown,
        currentPage: 0,
        pageCount: 0,
      },
    },
  );

  const deleteURL = `${endpoints.ARTICLES}/${actionArticle?.uuid}`;
  const [
    { data: deleteResult, loading: deleteLoading, error: deleteError },
    deleteArticle,
  ] = useRequest<ArticleResponse>(
    deleteURL,
    'delete',
    {
      authToken: credentialsInfo?.token,
    },
    { manual: true },
  );

  const changeStatusURL = `${endpoints.ARTICLES}/${actionArticleState?.articleId}`;
  const [
    { data: changeStatusResult, loading: changeStatusLoading, error: changeStatusError },
    changeStatus,
  ] = useRequest<ArticleResponse>(
    changeStatusURL,
    'patch',
    {
      authToken: credentialsInfo?.token,
    },
    { manual: true },
  );

  const onDeleteArticle = () => {
    deleteArticle();
  };

  useEffect(() => {
    if (!actionArticleState) return;
    const formData = new FormData();
    formData.append('status', actionArticleState.status);

    changeStatus({
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
        authorization: credentialsInfo?.token,
      },
    });
  }, [actionArticleState]);

  useEffect(() => {
    setLoading(loading || changeStatusLoading);
  }, [loading, changeStatusLoading, setLoading]);

  useEffect(() => {
    if (error) {
      const message = getApiErrorMessage(error);
      setSimpleToasts({ type: 'danger', message, show: true });
    }
    if (data) {
      const { recent, data: articles = [] } = data.result;

      if (recent !== undefined) setRecentData(recent);
      if (articles !== undefined) setArticlesData(articles);
    }
  }, [loading, data, error, setSimpleToasts, setLoading]);

  useEffect(() => {
    if (deleteResult) {
      refetch();
      setModal(false);
    }
    if (changeStatusResult) {
      refetch();
      setActionArticleState(undefined);
    }
    if (deleteError) {
      const message = getApiErrorMessage(deleteError);
      setSimpleToasts({ type: 'danger', message, show: true });
    }
  }, [deleteResult, deleteError, changeStatusResult]);

  useEffect(() => {
    if (categoriesError) {
      const message = getApiErrorMessage(categoriesError);
      setSimpleToasts({ type: 'danger', message, show: true });
    }
    if (categoriesData) {
      const { result } = categoriesData;
      setCategories(result);
    }
  }, [categoriesLoading, categoriesData, categoriesError, setSimpleToasts, setLoading]);

  useEffect(() => {
    if (location.pathname === routes.USERS_ARTICLES && !loading) {
      refetch();
    }
  }, [location.pathname]);

  const onCategoryItemClick = (categoryItem: string, isSelected: boolean) => {
    if (isSelected) {
      const newList = category.filter(item => item !== categoryItem);
      setCategory(newList);
    } else {
      const newList = [...category];
      newList.push(categoryItem);
      setCategory(newList);
    }
  };

  const renderCategoryItem = (categoryItem: Categories) => {
    const isSelected = category.includes(categoryItem.name);
    return (
      <div
        className="drop-down-status-item"
        style={{ backgroundColor: isSelected ? '#F6F6F6' : '#FFFFFF' }}
        onClick={() => onCategoryItemClick(categoryItem.name, isSelected)}
      >
        <img
          className="checkbox-icon"
          src={isSelected ? CheckIcon : UnCheckIcon}
          alt="check-icon"
        />
        <div>{categoryItem.name}</div>
      </div>
    );
  };

  const onCancelStatus = () => {
    setStatus([]);
    setStatusShow(false);
  };

  const onApplyStatus = () => {
    setFilters({ ...filters, status });
    setStatusShow(false);
    refetch();
  };

  const onCancelCategory = () => {
    setCategory([]);
    setCategoryShow(false);
  };

  const onApplyCategory = () => {
    setFilters({ ...filters, categories: category });
    setCategoryShow(false);
    refetch();
  };

  return (
    <Container fluid className="article-page-container p-0">
      <Header title={`User Articles — ${articlesData.length}`} />

      <div className="user-articles-container">
        <Row>
          <Col xs={7}>
            <div className="user-articles-title-text">Recently Viewed Articles</div>
          </Col>
        </Row>
        <Row>
          {recentData?.map(articleData => (
            <Col xs={6} sm={3}>
              <ArticleCard
                values={articleData}
                imageUrl={articleData?.media[0]?.uri}
                type="recent"
              />
            </Col>
          ))}
        </Row>
      </div>
      <div className="user-articles-container ">
        <Row>
          <Col xs={12}>
            <div className="user-articles-title-text"> All Articles </div>
          </Col>
        </Row>
        <Row style={{ marginTop: '10px' }}>
          <Col xs={7}>
            <div
              ref={categoryTarget}
              onClick={() => setCategoryShow(!categoryShow)}
              className="article-category-container"
            >
              <div className="category-text-style">
                {category.length === 0 ? 'All Categories' : category[0]}
                {category.length > 1 && ' and '}
                {category.length > 1 && (
                  <div className="more-text-in-dropdown">+{category.length - 1} More</div>
                )}
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          {articlesData?.map(articleData => (
            <Col xs={12} sm={6}>
              <ArticleCard
                values={articleData}
                imageUrl={articleData?.media[0]?.uri}
                type="article"
              />
            </Col>
          ))}
        </Row>
        <Row>
          <Col xs={12}>
            <Button
              onClick={() => setArticlesShown(articlesShown + 2)}
              className=" load-more-button"
            >
              Load more
            </Button>
          </Col>
        </Row>
      </div>

      <Overlay
        onHide={() => setCategoryShow(false)}
        target={categoryTarget.current}
        rootClose
        show={categoryShow}
        placement="bottom"
      >
        <Popover id="popover-positioned-bottom">
          <Popover.Body className="property_overlay">
            <div className="drop-down-container-status">
              {categories.map(renderCategoryItem)}
              <div className="apply-and-cancle-container">
                <div onClick={onCancelCategory} className="cancle-text-container">
                  Cancel
                </div>
                <div onClick={onApplyCategory} className="apply-text-container">
                  Apply
                </div>
              </div>
            </div>
          </Popover.Body>
        </Popover>
      </Overlay>
      <Overlay
        onHide={() => setStatusShow(false)}
        target={statusTarget.current}
        rootClose
        show={statusShow}
        placement="bottom"
      >
        <Popover id="popover-positioned-bottom">
          <Popover.Body className="property_overlay">
            <div className="drop-down-container-status">
              <div className="apply-and-cancle-container">
                <Button onClick={onCancelStatus} className="cancle-text-container">
                  Cancel
                </Button>
                <Button
                  onClick={onApplyStatus}
                  className="apply-text-container"
                  disabled={deleteLoading}
                >
                  {deleteLoading ? 'Loading…' : 'Apply'}
                </Button>
              </div>
            </div>
          </Popover.Body>
        </Popover>
      </Overlay>

      <AddArticles
        article={actionArticle}
        show={addArticle}
        key={JSON.stringify(actionArticle)}
        categories={categories}
        onSave={() => setAddArticle(false)}
        onHide={() => {
          setAddArticle(false);
          setActionArticle(undefined);
        }}
        refetch={refetch}
      />
      <DeleteModal
        show={modal}
        onDelete={onDeleteArticle}
        onHide={() => {
          setModal(false);
          setActionArticle(undefined);
        }}
        title="Delete Article"
        description="Are you sure you want to delete this article?"
      />
    </Container>
  );
};
