import { FC, useState, useEffect, useCallback, useRef } from 'react';
import Container from 'react-bootstrap/Container';
import { Overlay, Popover } from 'react-bootstrap';
import { endpoints } from '../../../modules/mappers/urls';
import {
  useAuth,
  useLoading,
  useNotifications,
  useRequest,
} from '../../../modules/hooks';
import { getApiErrorMessage } from '../../../modules/utils/transform';
import { Categories, UserResponse, Users } from '../../../modules/interfaces';
import './styles/styles.css';
import Header from '../../Header';
import DropDownIcon from '../../../assets/drop-down-icon.svg';
import DropDownOpenIcon from '../../../assets/drop-down-open.svg';
import UnCheckIcon from '../../../assets/uncheck-checkbox.svg';
import CheckIcon from '../../../assets/check-checkbox.svg';
import PlusIcon from '../../../assets/plus-icon.svg';
import DeleteModal from '../../Modals/DeleteModal/DeleteModal';
import { UsersTable } from '../../Tables/UsersTable';
import AddUsers from '../../Modals/AddUsers/AddUsers';
import { UserStatusRequest, UserUpdateRequest } from '../../Modals/UserManagementModal';
import { UserStatus } from '../../../modules/enums/status';

export const UserManagement: FC = () => {
  const { setSimpleToasts } = useNotifications()!;
  const [modal, setModal] = useState<boolean>(false);
  const [suspendModal, setSuspendModal] = useState<boolean>(false);
  const [categories, setCategories] = useState<Categories[]>([]);

  const [tableData, setTableData] = useState<Users[]>([]);

  const [status, setStatus] = useState<string[]>(['All status']);
  const [category, setCategory] = useState<string[]>(['All category']);
  const [categoryFinal, setCategoryFinal] = useState<string[]>(['All category']);
  const [statusFinal, setStatusFinal] = useState<string[]>(['All status']);
  const statusArray = ['All status', 'Inactive', 'Active', 'Suspended'];
  const [categoryShow, setCategoryShow] = useState(false);
  const categoryTarget = useRef(null);
  const [statusShow, setStatusShow] = useState(false);
  const [addUsers, setAddUsers] = useState(false);
  const [editUsers, setEditUsers] = useState(false);
  const [resetpass, setResetPass] = useState(false);
  const [actionUsers, setActionUsers] = useState<Users | undefined>();
  const statusTarget = useRef(null);

  const { setLoading } = useLoading()!;
  const { credentialsInfo } = useAuth()!;

  const [{ loading: updateLoading, data: updateResult, error: updateError }, updateUser] =
    useRequest<UserUpdateRequest>(
      `${endpoints.USERS}/${actionUsers?.email}`,
      'patch',
      {
        authToken: credentialsInfo?.token,
      },
      { manual: true, autoCancel: false },
    );

  const [{ data, loading, error }, refetch] = useRequest<UserResponse>(
    endpoints.USERS,
    'get',
    {
      authToken: credentialsInfo?.token,
    },
  );

  const [{ data: categoriesData, loading: categoriesLoading, error: categoriesError }] =
    useRequest<Categories[]>(endpoints.CATEGORIES, 'get', {
      authToken: credentialsInfo?.token,
    });

  useEffect(() => {
    setLoading(loading || updateLoading);
  }, [loading, setLoading, updateLoading]);

  useEffect(() => {
    if (updateError) {
      const message = getApiErrorMessage(updateError);
      setSimpleToasts({ type: 'danger', message, show: true });
    }

    if (updateResult) {
      refetch();
    }
  }, [updateResult, updateError, setSimpleToasts, refetch]);

  useEffect(() => {
    if (error) {
      const message = getApiErrorMessage(error);
      setSimpleToasts({ type: 'danger', message, show: true });
    }
    if (data) {
      const { result } = data;
      setTableData(result.data);
    }
  }, [data, error, setSimpleToasts, setLoading]);

  const refreshHandler = useCallback(async () => {
    refetch({
      headers: {
        authorization: credentialsInfo?.token,
      },
    });
  }, [refetch, credentialsInfo]);

  useEffect(() => {
    if (categoriesError) {
      const message = getApiErrorMessage(categoriesError);
      setSimpleToasts({ type: 'danger', message, show: true });
    }
    if (categoriesData) {
      const { result } = categoriesData;
      setCategories(result);
    }
  }, [categoriesLoading, categoriesData, categoriesError, setSimpleToasts, setLoading]);

  const onStatusItemClick = (statusItem: string, isSelected: boolean) => {
    if (isSelected) {
      const newList = status.filter(item => item !== statusItem);
      setStatus(newList);
    } else {
      const newList = [...status];
      newList.push(statusItem);
      setStatus(newList);
    }
  };
  const onCategoryItemClick = (categoryItem: string, isSelected: boolean) => {
    if (isSelected) {
      const newList = category.filter(item => item !== categoryItem);
      setCategory(newList);
    } else {
      const newList = [...category];
      newList.push(categoryItem);
      setCategory(newList);
    }
  };
  const onAddUser = () => {
    setAddUsers(true);
  };

  const renderCategryItem = (categoryItem: Categories) => {
    const isSelected = category.includes(categoryItem.name);
    return (
      <div
        className="drop-down-status-item"
        style={{ backgroundColor: isSelected ? '#F6F6F6' : '#FFFFFF' }}
        onClick={() => onCategoryItemClick(categoryItem.name, isSelected)}
      >
        <img
          className="checkbox-icon"
          src={isSelected ? CheckIcon : UnCheckIcon}
          alt="check-icon"
        />
        <div>{categoryItem.name}</div>
      </div>
    );
  };

  const renderStatusItem = (statusItem: string) => {
    const isSelected = status.includes(statusItem);
    return (
      <div
        className="drop-down-status-item"
        style={{ backgroundColor: isSelected ? '#F6F6F6' : '#FFFFFF' }}
        onClick={() => onStatusItemClick(statusItem, isSelected)}
      >
        <img
          className="checkbox-icon"
          src={isSelected ? CheckIcon : UnCheckIcon}
          alt="check-icon"
        />
        <div>{statusItem}</div>
      </div>
    );
  };

  const onCancleStatus = () => {
    setStatus(statusFinal);
    setStatusShow(false);
  };

  const onApplyStatus = () => {
    if (status.length > 0) {
      setStatusFinal(status);
      setStatusShow(false);
    }
  };

  const onCancleCategory = () => {
    setCategory(categoryFinal);
    setCategoryShow(false);
  };

  const onApplyCategory = () => {
    if (category.length > 0) {
      setCategoryFinal(category);
      setCategoryShow(false);
    }
  };

  const onSuspendOrActiveUser = () => {
    const newStatus =
      actionUsers?.status === UserStatus.SUSPENDED
        ? UserStatus.ACTIVE
        : UserStatus.SUSPENDED;
    const suspendData: UserStatusRequest = {
      status: newStatus,
    };
    updateUser({
      data: suspendData,
      headers: {
        authorization: credentialsInfo?.token,
      },
    });

    setSuspendModal(false);
    setModal(false);
    setActionUsers(undefined);
  };

  const getSuspendModalTitle = () => {
    if (suspendModal) {
      if (actionUsers?.status === 'SUSPENDED') {
        return 'Do you want to activate this user?';
      }
      return 'Do you want to suspend this user?';
    }
    return 'Are you sure you want to delete this user?';
  };

  const getSuspendModalDesc = () => {
    if (suspendModal) {
      if (actionUsers?.status === 'SUSPENDED') {
        return 'Activating this user will allow them from accessing the system and performing any actions. You can suspend their account at any time.';
      }
      return 'Suspending this user will prevent them from accessing the system and performing any actions. You can reactivate their account at any time. ';
    }
    return "Deleting this user will permanently remove them from the system. Make sure you don't need this username before continuing.";
  };

  return (
    <Container fluid className="article-page-container p-0">
      <Header title="User Management — 46" />
      <div className="artical-text-main-container">
        <div className="artical-dropdown-container ">
          <div
            ref={categoryTarget}
            onClick={() => setCategoryShow(!categoryShow)}
            className="category-text-container"
          >
            <div className="category-text-style">
              {categoryFinal[0]} {categoryFinal.length > 1 && ' and '}
              {categoryFinal.length > 1 && (
                <div className="more-text-in-dropdown">
                  +{categoryFinal.length - 1} More
                </div>
              )}
            </div>
            <img src={categoryShow ? DropDownOpenIcon : DropDownIcon} alt="drop-down" />
          </div>
          <div
            ref={statusTarget}
            onClick={() => setStatusShow(!statusShow)}
            className="category-text-container"
          >
            <div className="category-text-style">
              {statusFinal[0]}
              {statusFinal.length > 1 && ' and '}
              {statusFinal.length > 1 && (
                <div className="more-text-in-dropdown">
                  +{statusFinal.length - 1} More
                </div>
              )}
            </div>
            <img src={statusShow ? DropDownOpenIcon : DropDownIcon} alt="drop-down" />
          </div>
        </div>
        <div onClick={onAddUser} className="add-artical-container">
          <img src={PlusIcon} alt="plus-icon" />
          <div className="add-artical-text-style">Add user</div>
        </div>
      </div>
      <Overlay
        onHide={() => setCategoryShow(false)}
        target={categoryTarget.current}
        rootClose
        show={categoryShow}
        placement="bottom"
      >
        <Popover id="popover-positioned-bottom">
          <Popover.Body className="property_overlay">
            <div className="drop-down-container-status">
              {categories.map(renderCategryItem)}
              <div className="apply-and-cancle-container">
                <div onClick={onCancleCategory} className="cancle-text-container">
                  Cancel
                </div>
                <div onClick={onApplyCategory} className="apply-text-container">
                  Apply
                </div>
              </div>
            </div>
          </Popover.Body>
        </Popover>
      </Overlay>
      <Overlay
        onHide={() => setStatusShow(false)}
        target={statusTarget.current}
        rootClose
        show={statusShow}
        placement="bottom"
      >
        <Popover id="popover-positioned-bottom">
          <Popover.Body className="property_overlay">
            <div className="drop-down-container-status">
              {statusArray.map(renderStatusItem)}
              <div className="apply-and-cancle-container">
                <div onClick={onCancleStatus} className="cancle-text-container">
                  Cancel
                </div>
                <div onClick={onApplyStatus} className="apply-text-container">
                  Apply
                </div>
              </div>
            </div>
          </Popover.Body>
        </Popover>
      </Overlay>
      <UsersTable
        searchString=""
        tableData={tableData}
        refreshHandler={refreshHandler}
        onEditUser={user => {
          setActionUsers(user);
          setEditUsers(true);
        }}
        onDeleteUser={user => {
          setActionUsers(user);
          setModal(true);
        }}
        onSuspendUser={user => {
          setActionUsers(user);
          setSuspendModal(true);
        }}
        onResetPassword={user => {
          setActionUsers(user);
          setResetPass(true);
        }}
      />
      <AddUsers
        users={actionUsers}
        show={editUsers || resetpass || addUsers}
        isEditUsers={editUsers}
        isResetPassword={resetpass}
        key={JSON.stringify(actionUsers)}
        onSave={() => {
          setResetPass(false);
          setAddUsers(false);
          setEditUsers(false);
        }}
        onHide={() => {
          setResetPass(false);
          setAddUsers(false);
          setEditUsers(false);
        }}
      />
      <DeleteModal
        show={suspendModal || modal}
        onDelete={onSuspendOrActiveUser}
        onHide={() => {
          setSuspendModal(false);
          setModal(false);
          setActionUsers(undefined);
        }}
        isUserDelete={modal}
        isUsersuspend={suspendModal}
        title={getSuspendModalTitle()}
        description={getSuspendModalDesc()}
      />
    </Container>
  );
};
