import { FC, useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {
  Whatsapp,
  Envelope,
  GeoAlt,
  Facebook,
  Instagram,
  Tiktok,
} from 'react-bootstrap-icons';

import './styles/styles.css';

import ReactStars from 'react-rating-star-with-type';

import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { AxiosError } from 'axios';
import { SocialNetworkCard } from '../Cards/SocialNetworkCard';
import {
  ErrorEnvelop,
  useGuestAuth,
  useNotifications,
  useRequest,
} from '../../modules/hooks';
import { ReservationRatingResponse } from '../../modules/interfaces';
import { endpoints } from '../../modules/mappers/urls';
import { getApiErrorMessage } from '../../modules/utils/transform';
import { GuestPortalErrors } from '../Modals/GuestPagesModals/constants/GuestPortalConstants';
import { openSMSApp } from '../../modules/utils/sendMessage';
import {
  coachHouseEmail,
  coachHouseTiktokLink,
  coachHouseFacebookLink,
  coachHouseInstagramLink,
} from '../../constants/generalData';

export const Feedback: FC = () => {
  const ICON_SIZE = 28;
  const { reservationId } = useParams();
  const navigate = useNavigate();
  const { token, generateAuthToken } = useGuestAuth()!;
  const location = useLocation();
  const { guestEmail } = location.state;
  const { setSimpleToasts } = useNotifications()!;

  const [ratingValue, setRatingValue] = useState(0);

  const [
    { data: ratingResult, loading: loadingRating, error: ratingError },
    updateReservationRating,
  ] = useRequest<ReservationRatingResponse>(
    endpoints.RATING,
    'patch',
    {
      authGuestToken: token,
    },
    { manual: true },
  );

  const ratingChanged = (rating: number) => {
    const dataVal = { reservationId, rating, guestEmail };

    setRatingValue(rating || 0);

    updateReservationRating({
      data: dataVal,
      headers: {
        'reservation-token': token,
      },
    });
  };

  useEffect(() => {
    if (token) return;

    navigate(`/guest-portal/${reservationId}`);
  }, [navigate, reservationId, token]);

  useEffect(() => {
    if (ratingResult) {
      setSimpleToasts({
        type: 'success',
        message: 'Thank you! Your rating has been submitted.',
        show: true,
        delay: 3500,
      });
    }
  }, [ratingResult]);

  useEffect(() => {
    if (!ratingError) return;
    if (
      getApiErrorMessage(ratingError as AxiosError<ErrorEnvelop>) ===
      GuestPortalErrors.JWT_EXPIRED
    ) {
      generateAuthToken({ data: { reservationId } });
    }
  }, [ratingError, reservationId]);

  return (
    <>
      <Container className="container-paddings">
        <hr
          style={{
            marginTop: '45px',
          }}
        />
      </Container>
      <Container
        className="container-paddings direction-padding"
        id="guest-portal"
        style={{ height: '6rem' }}
      >
        <Row style={{ paddingLeft: '0px', paddingRight: '0px' }}>
          <Col
            xs={7}
            style={{
              display: 'flex',
              justifyContent: 'left',
              alignItems: 'center',
            }}
          >
            <GeoAlt size={26} />
            <div className="direction-title">
              284 Lafayette St,
              <br /> Salem Ma,
              <br /> 01970
              <br />
            </div>
          </Col>
          <Col
            xs={5}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'right',
            }}
          >
            <Envelope
              onClick={e => {
                window.location.href = `mailto:${coachHouseEmail}`;
                e.preventDefault();
              }}
              size={26}
              className="message-button-icon"
            />
            <Whatsapp
              onClick={openSMSApp}
              size={26}
              className="message-button-icon"
              style={{ marginLeft: '30px' }}
            />
          </Col>
        </Row>
      </Container>
      <Container className="container-paddings" id="guest-portal">
        <Row className="m-0 justify-content-center w-100 align-items-center">
          <Col lg={6} style={{ display: 'flex', justifyContent: 'center' }}>
            <div className="social-media-title">
              <div>How was your stay?</div>
              <ReactStars
                onChange={ratingChanged}
                value={ratingValue}
                size={40}
                isEdit
                isHalf
                activeColors={loadingRating ? ['gray'] : ['#FFCE00']}
              />
            </div>
          </Col>
        </Row>
      </Container>
      <Container
        className="container-paddings"
        id="guest-portal"
        style={{ marginTop: '16px' }}
      >
        <Row className="m-0 justify-content-center w-100 align-items-center">
          <Col lg={6} style={{ display: 'flex', justifyContent: 'center' }}>
            <div className="social-media-title">
              Don&apos;t forget to follow us on Social Media and stay updated
            </div>
          </Col>
        </Row>
        <SocialNetworkCard
          title="The coach house Facebook"
          icon={<Facebook size={ICON_SIZE} className="card-icon" />}
          socialNetworkLink={coachHouseFacebookLink}
        />
        <SocialNetworkCard
          title="The coach house Instagram"
          icon={<Instagram size={ICON_SIZE} className="card-icon" />}
          socialNetworkLink={coachHouseInstagramLink}
        />
        <SocialNetworkCard
          title="The coach house Tiktok"
          icon={<Tiktok size={ICON_SIZE} className="card-icon" />}
          socialNetworkLink={coachHouseTiktokLink}
        />
      </Container>
    </>
  );
};
