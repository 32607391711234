import { FC, useCallback, useEffect, useState } from 'react';
import { Outlet, useOutlet } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import { IdleTimerProvider, useIdleTimer } from 'react-idle-timer';

import { Toast } from 'react-bootstrap';
import Pusher from 'pusher-js';
import { AuthProvider } from '../Providers/Auth';
import { config } from '../../modules/config';

import './styles/styles.css';
import EncloseIcon from '../../assets/enclose.svg';
import ExpandIcon from '../../assets/expand.svg';
import { useAuth, usePushNotifications } from '../../modules/hooks';
import { LoadingProvider } from '../Providers/Loading';
import Sidebar from '../sidebar';
import { PushNotification, ToastList } from '../../modules/interfaces';
import * as pusherConstants from '../../constants/pusherConstants';

export function AuthProviderLayout() {
  return (
    <AuthProvider>
      <Outlet />
    </AuthProvider>
  );
}

export const Layout: FC = () => {
  const outlet = useOutlet();
  const [isSidebarExpand, setSidebarExpand] = useState(true);
  const { signOut } = useAuth()!;
  const { reset } = useIdleTimer();

  const [windowWidth, setWindowWidth] = useState<number>(0);

  const { credentialsInfo } = useAuth()!;
  const [pusherState, setPusherState] = useState<Pusher | undefined>();
  const [toasts, setToasts] = useState<ToastList[]>([]);
  const notificationStore = usePushNotifications();

  const onIdle = () => {
    signOut();
  };
  const onAction = () => {
    reset();
  };

  const showToast = (notificationMessage: string) => {
    const newNotification = {
      id: Math.random().toString(36).substr(2, 9),
      notificationMessage,
    };
    setToasts(prevToasts => [...prevToasts, newNotification]);
  };

  const removeToast = (id: string) => {
    const value = toasts.filter(toast => toast.id !== id);
    setToasts(value);
  };

  const addNotificationMessage = (notificationMessage: string) => {
    const newNotification: PushNotification = {
      isRead: false,
      title: notificationMessage,
    };

    notificationStore?.setNotificationList((prevList: PushNotification[]) => [
      newNotification,
      ...prevList,
    ]);

    showToast(notificationMessage);
  };

  const pusherChannelAuthorization = (token: string) => {
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_NOTIFICATIONS_KEY || '', {
      cluster: process.env.REACT_APP_PUSHER_NOTIFICATIONS_CLUSTER || '',
      authEndpoint: `${process.env.REACT_APP_BOOKING_ENDPOINT}${pusherConstants.pusherEndpointURL}`,
      auth: {
        headers: {
          Authorization: token,
        },
      },
    });

    setPusherState(pusher);
    const channel = pusher.subscribe(pusherConstants.pusherNotificationChannel);
    channel.bind(pusherConstants.pusherNotificationEvent, (data: string) => {
      addNotificationMessage(data);
    });
  };

  useEffect(() => {
    const removeOldestToast = () => {
      if (toasts.length > 0) {
        const [, ...rest] = toasts;
        setToasts(rest);
      }
    };

    if (toasts.length > 8) {
      removeOldestToast();
    }

    const timer = setTimeout(removeOldestToast, 5000);
    return () => clearTimeout(timer);
  }, [toasts]);

  useEffect(() => {
    if (!credentialsInfo?.token || pusherState) return;
    pusherChannelAuthorization(credentialsInfo?.token);
  }, [credentialsInfo]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 900) {
        setSidebarExpand(false);
      }
      setWindowWidth(window.innerWidth);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const onToggleSidebar = useCallback(() => {
    setSidebarExpand(!isSidebarExpand);
  }, [isSidebarExpand]);

  return (
    <LoadingProvider>
      <IdleTimerProvider
        timeout={config.idle.timeoutMin * 60000}
        onIdle={onIdle}
        onAction={onAction}
      >
        <Container fluid className="mainContainer">
          {windowWidth > 900 && (
            <div
              onClick={onToggleSidebar}
              className="expand_icon"
              style={{ left: isSidebarExpand ? '259px' : '59px' }}
            >
              <img alt="toggle_img" src={isSidebarExpand ? EncloseIcon : ExpandIcon} />
            </div>
          )}

          <Sidebar expand={isSidebarExpand} />
          <Container fluid className="screenContainer">
            {outlet}
          </Container>
          {toasts.map((toast, index) => (
            <Toast
              key={toast.id}
              onClose={() => removeToast(toast.id)}
              animation
              style={{
                position: 'absolute',
                top: `${index * 115}px`,
                right: '10px',
                zIndex: toasts.length - index,
                transition: 'margin 0.2s',
              }}
            >
              <Toast.Header>
                <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
                <strong className="me-auto">New notification</strong>
              </Toast.Header>
              <Toast.Body>{toast.notificationMessage}</Toast.Body>
            </Toast>
          ))}
        </Container>
      </IdleTimerProvider>
    </LoadingProvider>
  );
};
