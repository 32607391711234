import { createContext, Dispatch, SetStateAction } from 'react';

export interface ToastSimpleNotifications {
  message?: string;
  type?: string;
  show: boolean;
  delay?: number;
}

export interface NotificationContextProps {
  simpleToasts?: ToastSimpleNotifications;
  setSimpleToasts: Dispatch<SetStateAction<ToastSimpleNotifications | undefined>>;
}

export const NotificationContext = createContext<NotificationContextProps | undefined>(
  undefined,
);
