import { FC, useEffect, useState } from 'react';

import Card from 'react-bootstrap/Card';
import './styles/styles.css';

import { OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Article } from '../../../modules/interfaces';
import { routes } from '../../../modules/mappers/urls';

interface PackageCardProps {
  values?: Article;
  imageUrl?: string;
  type?: 'recent' | 'article';
}

export const ArticleCard: FC<PackageCardProps> = ({
  values,
  type = 'article',
  imageUrl,
}) => {
  const [maxTitleLength, setMaxTitleLength] = useState(55);
  const navigate = useNavigate();

  const handleTitleLength = (articleTitle: number, recentTitle: number) => {
    if (type === 'article') {
      setMaxTitleLength(articleTitle);
    }
    if (type === 'recent') {
      setMaxTitleLength(recentTitle);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 600) {
        handleTitleLength(55, 30);
      }
      if (window.innerWidth <= 900) {
        handleTitleLength(55, 30);
      }
      if (window.innerWidth > 900) {
        handleTitleLength(70, 55);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  function getFirstParagraphText(html: string): string | null {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');

    const firstParagraph = doc.querySelector('p');

    return firstParagraph ? firstParagraph.textContent?.trim() || null : null;
  }

  return (
    <Card className={type === 'article' ? 'article-card' : 'recent-card'}>
      <Card.Img
        variant="top"
        src={imageUrl || '/coach-house-image-2.jpeg'}
        className={
          type === 'article'
            ? 'd-inline-block align-top article-card-image'
            : 'd-inline-block align-top article-recent-card-image'
        }
      />
      <Card.Body
        style={{
          marginTop: '8px',
          padding: '0px 17px 17px 17px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <span className="article-recent-subtitle">
            {values?.categories[0].name || ''}
          </span>
          <br />

          {values?.name && values?.name?.length < maxTitleLength ? (
            <span className="article-recent-title">{values?.name}</span>
          ) : (
            <OverlayTrigger overlay={<Tooltip>{values?.name}</Tooltip>}>
              <span className="article-recent-title">
                {values?.name.substring(0, maxTitleLength)} {' ...'}
              </span>
            </OverlayTrigger>
          )}
          {type === 'article' && (
            <Row>
              {values?.body && values?.body?.length < 225 ? (
                <div className="article-description">
                  {getFirstParagraphText(values?.body || '')}
                </div>
              ) : (
                <div className="article-description">
                  {getFirstParagraphText(values?.body || '')?.substring(0, 225)}
                  {' ...'}
                </div>
              )}
            </Row>
          )}
        </div>

        <Row style={{ display: 'flex', alignItems: 'flex-end' }}>
          <span
            className="read-more-text "
            onClick={() =>
              navigate({
                pathname: routes.ARTICLES_DETAILS,
                search: `?id=${values?.uuid}`,
              })
            }
          >
            READ MORE
          </span>
        </Row>
      </Card.Body>
    </Card>
  );
};
