import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { Button, Col, Image, Row } from 'react-bootstrap';

import './styles/styles.css';

import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import { RefetchFunction } from 'axios-hooks';
import { WithContext as ReactTags, Tag } from 'react-tag-input';
import { FileStore } from '../PostEditor';
import { useAuth, useNotifications, useRequest } from '../../../modules/hooks';

import { Property, Amenities, OptionElement } from '../../../modules/interfaces';

import { endpoints } from '../../../modules/mappers/urls';
import { getApiErrorMessage } from '../../../modules/utils/transform';
import { BasicFileUploader } from '../Basic/FileUploader';

interface PropertyEditorModalProps {
  data?: Property;
  duplicate?: boolean;
  show: boolean;
  handleClose: () => void;
  refetchProperties: RefetchFunction<unknown, unknown>;
}

interface PropertyAmenity {
  amenityId?: string;
  name: string;
  active: boolean;
}

export const PropertyEditorModal: FC<PropertyEditorModalProps> = ({
  data: propertyData,
  show,
  duplicate = false,
  handleClose,
  refetchProperties,
}) => {
  const { setSimpleToasts } = useNotifications()!;
  const [files, setFiles] = useState<FileStore[]>([]);
  const [fileLs, setFileList] = useState<FileList | null>(null);
  const [mediaDeleted, setMediaDeleted] = useState<string[]>([]);
  const [customAmenities, setCustomAmenities] = useState<Tag[]>([]); // store the custom amenities

  // establish the default amenities, and set them to false
  const [defaultPropertyAmenities, setDefaultPropertyAmenities] = useState<
    PropertyAmenity[]
  >([]);

  const [customAmenitiesSuggestions, setCustomAmenitiesSuggestions] = useState<Tag[]>([]);
  const fileTypes = useMemo(() => ['JPEG', 'JPG', 'PNG', 'GIF'], []);

  const {
    register,
    handleSubmit,
    reset,
    trigger,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
  });
  const { credentialsInfo } = useAuth()!;

  const [logoFile, setLogoFile] = useState<File | undefined>(undefined);

  const logoInputField = useRef<HTMLInputElement>(null);

  const [
    {
      data: optionsAmenitiesData,
      loading: optionsAmenitiesLoading,
      error: optionsAmenitiesError,
    },
  ] = useRequest<OptionElement[]>(`${endpoints.OPTIONS}/property-amenities`, 'get', {
    authToken: credentialsInfo?.token,
  });

  const [
    {
      data: optionsAmenitiesSuggestionsData,
      loading: optionsAmenitiesSuggestionsLoading,
      error: optionsAmenitiesSuggestionsError,
    },
  ] = useRequest<OptionElement[]>(
    `${endpoints.OPTIONS}/property-amenities-suggestions`,
    'get',
    {
      authToken: credentialsInfo?.token,
    },
  );

  const [
    { data: insertResult, loading: insertLoading, error: insertError },
    insertProperty,
  ] = useRequest<string>(
    endpoints.PROPERTIES,
    'post',
    {
      authToken: credentialsInfo?.token,
    },
    { manual: true },
  );

  const updatePropertyUrl = `${endpoints.PROPERTIES}/${propertyData?.uuid}`;
  const [
    { data: updateResult, loading: updateLoading, error: updateError },
    updateProperty,
  ] = useRequest<string>(
    updatePropertyUrl,
    'put',
    {
      authToken: credentialsInfo?.token,
    },
    { manual: true },
  );

  function isUUID(uuid: string) {
    const regexUUID =
      /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    return regexUUID.test(uuid);
  }

  const handleAdditionCustomAmenity = (tag: Tag) => {
    setCustomAmenities((prevAmenities: Tag[]) => [...prevAmenities, tag]);
  };

  const handleDeleteCustomAmenity = (i: number) => {
    setCustomAmenities((prevAmenities: Tag[]) =>
      prevAmenities.filter((tag, index) => index !== i),
    );
  };

  const handleDragCustomAmenity = (tag: Tag, currPos: number, newPos: number) => {
    const newTags = customAmenities.slice();
    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);
    setCustomAmenities(newTags);
  };

  const retrieveAmenitiesInformation = (): [
    PropertyAmenity[], // amenities to add
    string[], // amenities to delete
    PropertyAmenity[], // amenities to update
  ] => {
    const amenitiesToAdd: PropertyAmenity[] = [];
    const amenitiesToDelete: string[] = [];
    const amenitiesToUpdate: PropertyAmenity[] = [];

    // if ther are no propertyData (new property) or there are no propertyAmenities, add all default amenities
    if (!propertyData || !propertyData.propertyAmenities || duplicate) {
      defaultPropertyAmenities.forEach(item => {
        amenitiesToAdd.push(item);
      });
    }

    // insert to add all custom amenities that id is not uuid (newly added)
    customAmenities.forEach(item => {
      if (!isUUID(item.id)) {
        amenitiesToAdd.push({ name: item.text, active: true });
      }
    });

    // delete all PropertyAmenity that not exists in defaultPropertyAmenities or customAmenities
    if (propertyData && propertyData.propertyAmenities) {
      propertyData.propertyAmenities.forEach(item => {
        const found = defaultPropertyAmenities.find(tag => tag.name === item.name);
        const foundCustom = customAmenities.find(tag => tag.text === item.name);
        if (!found && !foundCustom) {
          amenitiesToDelete.push(item.uuid);
        }
      });
    }

    // update all defaultPropertyAmenities that exists in propertyData.propertyAmenities
    defaultPropertyAmenities.forEach(item => {
      const found = propertyData?.propertyAmenities?.find(tag => tag.name === item.name);
      if (found) {
        amenitiesToUpdate.push({ amenityId: found.uuid, ...item });
      }
    });

    // return the three arrays
    return [amenitiesToAdd, amenitiesToDelete, amenitiesToUpdate];
  };

  const handleFileChange = useCallback(
    (fileList: FileList) => {
      Array.from(fileList).forEach(file => {
        const fileAdded = files.some(item => item.file.name === file.name);

        if (!fileAdded) {
          const fileUrl = URL.createObjectURL(file);
          setFiles([...files, { id: undefined, url: fileUrl, file }]);
        }
      });
    },
    [files],
  );

  const handleDeleteFile = useCallback(
    (fileUrl: string, uuid: string | undefined) => {
      const filteredFiles = files.filter(item => item.url !== fileUrl);
      const list = new DataTransfer();

      URL.revokeObjectURL(fileUrl);

      filteredFiles.forEach(fileStore => list.items.add(fileStore.file));

      const { files: dtFiles } = list;

      if (uuid) {
        setMediaDeleted([...mediaDeleted, uuid]);
      }

      setFileList(dtFiles.length ? dtFiles : null);
      setFiles(filteredFiles);
    },
    [files, mediaDeleted],
  );

  const handleLogoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const propertyFiles = e.target.files?.[0] || undefined;
    if (propertyFiles) {
      setLogoFile(propertyFiles);
    }
  };
  const getModalTitle = useCallback(() => {
    if (duplicate) {
      return 'Duplicate this property';
    }
    if (propertyData) {
      return 'Edit this property';
    }
    return 'Add new property';
  }, [duplicate, propertyData]);

  const handleLoadAmenitiesOfproperty = (amenities?: Amenities[]) => {
    if (!optionsAmenitiesData) return;

    if (!amenities) {
      setDefaultPropertyAmenities([]);
      setCustomAmenities([]);
      return;
    }

    const amenityMap = new Map<string, boolean>(
      amenities.map(item => [item.name, item.active]),
    );

    const defaultAmenitiesRetrieves: PropertyAmenity[] = optionsAmenitiesData.result.map(
      item => ({
        name: item.name,
        active: amenityMap.get(item.name) || false,
      }),
    );

    const existingAmenities = new Set(
      optionsAmenitiesData.result.map(({ name }) => name),
    );

    const customAmenitiesRetrieves: Tag[] = amenities
      .filter(({ name }) => !existingAmenities.has(name))
      .map(({ name, uuid }) => ({
        id: duplicate ? name : uuid,
        text: name,
      }));

    setDefaultPropertyAmenities(defaultAmenitiesRetrieves);
    setCustomAmenities(customAmenitiesRetrieves);
  };

  const resetAmenitiesFields = () => {
    if (!optionsAmenitiesData) return;
    const defaultAmenitiesRetrieves: PropertyAmenity[] = optionsAmenitiesData.result.map(
      item => ({
        name: item.name,
        active: false,
      }),
    );

    const customAmenitiesRetrieves: Tag[] = [];

    setDefaultPropertyAmenities(defaultAmenitiesRetrieves);
    setCustomAmenities(customAmenitiesRetrieves);
  };

  useEffect(() => {
    if (!propertyData) {
      resetAmenitiesFields(); // reset the amenities fields, if is new property
    }
    if (!propertyData || !show) return;
    reset({
      name: duplicate ? `${propertyData.name} (Copy)` : propertyData.name,
      streetNumber: propertyData?.streetNumber || '',
      streetName: propertyData?.streetName || '',
      city: propertyData?.city || '',
      state: propertyData?.state || '',
      zipCode: propertyData?.zipCode || '',
    });

    if (propertyData?.logoUrl) {
      const fileData = new Blob(['Ejemplo de contenido del archivo'], {
        type: 'text/plain',
      });

      const newFileLogo = new File([fileData], propertyData.logoUrl);
      setLogoFile(newFileLogo);
    }

    if (propertyData?.media && propertyData?.media.length > 0) {
      const mediaData = propertyData?.media.map((item): FileStore => {
        return { id: item.uuid, url: item.uri, file: new File([], '') };
      });
      setFiles(mediaData);
    }

    if (propertyData.propertyAmenities) {
      handleLoadAmenitiesOfproperty(propertyData.propertyAmenities);
    }
  }, [show]);

  useEffect(() => {
    if (!optionsAmenitiesData) return;

    const defaultAmenitiesRetrieves: PropertyAmenity[] = optionsAmenitiesData.result.map(
      item => ({
        name: item.name,
        active: false,
      }),
    );

    setDefaultPropertyAmenities(defaultAmenitiesRetrieves);
  }, [optionsAmenitiesData]);

  useEffect(() => {
    if (!optionsAmenitiesSuggestionsData) return;

    const customAmenitiesSuggestionsRetrieves: Tag[] =
      optionsAmenitiesSuggestionsData.result.map(item => ({
        id: item.name,
        text: item.name,
      }));

    setCustomAmenitiesSuggestions(customAmenitiesSuggestionsRetrieves);
  }, [optionsAmenitiesSuggestionsData]);

  const handleCloseModal = () => {
    reset({
      name: '',
      streetNumber: '',
      streetName: '',
      city: '',
      state: '',
      zipCode: '',
    });
    setLogoFile(undefined);
    setFiles([]);
    handleClose();
  };

  const saveProperty = (data: Property) => {
    const formData = new FormData();

    formData.append('name', data.name || '');
    formData.append('streetName', data.streetName || '');
    formData.append('streetNumber', data.streetNumber || '');
    formData.append('city', data.city);
    formData.append('zipCode', data?.zipCode || '0');
    formData.append('state', data.state);

    if (logoFile) {
      formData.append('logo', logoFile, logoFile.name);
    }

    if (files) {
      const media = files.filter(file => file.file.name !== '').map(file => file.file);
      media.forEach(file => {
        const fileName = file.name;
        formData.append('files', file, fileName);
      });
    }

    const [amenitiesToAdd, amenitiesToDelete, amenitiesToUpdate] =
      retrieveAmenitiesInformation();

    if (!propertyData || duplicate) {
      // Add the amenities to the form data
      formData.append('amenities', JSON.stringify(amenitiesToAdd));

      insertProperty({
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
          authorization: credentialsInfo?.token,
        },
      });
    } else if (propertyData && !duplicate) {
      // Add the amenities to the form data
      formData.append('addAmenities', JSON.stringify(amenitiesToAdd));
      formData.append('deleteAmenities', JSON.stringify(amenitiesToDelete));
      formData.append('updateAmenities', JSON.stringify(amenitiesToUpdate));

      if (mediaDeleted.length > 0) {
        formData.append('mediaDeleted', JSON.stringify(mediaDeleted));
      }

      updateProperty({
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
          authorization: credentialsInfo?.token,
        },
      });
    }
  };

  const onSubmit = async (data: any) => {
    const validated = await trigger([
      'name',
      'streetNumber',
      'streetName',
      'city',
      'state',
      'zipCode',
    ]);

    if (validated) {
      saveProperty(data);
    }
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = e.target;
    const newAmenitiesStatus = defaultPropertyAmenities.map(item => {
      if (item.name === value) {
        return { ...item, active: checked };
      }
      return item;
    });
    setDefaultPropertyAmenities(newAmenitiesStatus);
  };

  useEffect(() => {
    if (insertResult || updateResult) {
      handleCloseModal();

      refetchProperties();
    }

    if (insertError) {
      const message = getApiErrorMessage(insertError);
      setSimpleToasts({ type: 'danger', message, show: true });
    }
    if (updateError) {
      const message = getApiErrorMessage(updateError);
      setSimpleToasts({ type: 'danger', message, show: true });
    }
  }, [insertResult, updateResult, updateError, insertError]);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="xl"
      dialogClassName="post-editor-modal"
      backdrop="static"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header>
          <Modal.Title className="editor-title">Property</Modal.Title>
          <span className="editor-sub-title">{getModalTitle()}</span>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label className="editor-title-label">Property Name</Form.Label>
              <Form.Control
                className="editor-control"
                placeholder="Enter property name..."
                {...register('name', { required: 'This is required.' })}
              />
            </Form.Group>
            <ErrorMessage
              errors={errors}
              name="name"
              render={({ message }) => <span className="error-text">{message}</span>}
            />
            <Row className="mt-48px">
              <Col md={6}>
                <Form.Label className="editor-title-label">Street Number</Form.Label>
                <Form.Control
                  className="editor-control"
                  placeholder="Enter property street number..."
                  {...register('streetNumber', { required: 'This is required.' })}
                />
                <ErrorMessage
                  errors={errors}
                  name="streetNumber"
                  render={({ message }) => <span className="error-text">{message}</span>}
                />
              </Col>
              <Col md={6}>
                <Form.Label className="editor-title-label">Street Name</Form.Label>
                <Form.Control
                  className="editor-control"
                  placeholder="Enter property street name..."
                  {...register('streetName', { required: 'This is required.' })}
                />
                <ErrorMessage
                  errors={errors}
                  name="streetName"
                  render={({ message }) => <span className="error-text">{message}</span>}
                />
              </Col>
            </Row>
            <Row className="mt-24px">
              <Col md={6}>
                <Form.Label className="editor-title-label">City</Form.Label>
                <Form.Control
                  className="editor-control"
                  placeholder="Enter property city..."
                  {...register('city', { required: 'This is required.' })}
                />
                <ErrorMessage
                  errors={errors}
                  name="city"
                  render={({ message }) => <span className="error-text">{message}</span>}
                />
              </Col>
              <Col md={6}>
                <Form.Label className="editor-title-label">State</Form.Label>
                <Form.Control
                  className="editor-control"
                  placeholder="Enter property state..."
                  {...register('state', { required: 'This is required.' })}
                />
                <ErrorMessage
                  errors={errors}
                  name="state"
                  render={({ message }) => <span className="error-text">{message}</span>}
                />
              </Col>
            </Row>
            <Row className="mt-24px">
              <Col md={6}>
                <Form.Label className="editor-title-label">ZIP Code</Form.Label>
                <Form.Control
                  className="editor-control"
                  placeholder="Enter property zip code..."
                  {...register('zipCode', { required: 'This is required.' })}
                />
                <ErrorMessage
                  errors={errors}
                  name="zipCode"
                  render={({ message }) => <span className="error-text">{message}</span>}
                />
              </Col>
            </Row>

            <Row className="mt-24px">
              <Col md={12}>
                <Form.Label className="editor-title-label">Amenities</Form.Label>
                <Row>
                  {defaultPropertyAmenities.map((item, index) => (
                    <Col xs={12} sm={6} key={item.name}>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value={item.name}
                          id={`flexCheck${index}`}
                          checked={item.active}
                          onChange={e => handleCheckboxChange(e)}
                        />
                        <label className="form-check-label" htmlFor={`flexCheck${index}`}>
                          {item.name}
                        </label>
                      </div>
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>

            <Row className="mt-24px">
              <Col md={12}>
                <Form.Label className="editor-title-label">
                  Additional Custom Amenities
                </Form.Label>
                <div className="ReactTags__tags-wrapper">
                  <ReactTags
                    tags={customAmenities}
                    handleDelete={handleDeleteCustomAmenity}
                    handleAddition={handleAdditionCustomAmenity}
                    handleDrag={handleDragCustomAmenity}
                    placeholder="Add custom amenity..."
                    inputFieldPosition="inline"
                    suggestions={customAmenitiesSuggestions}
                    classNames={{
                      tags: 'ReactTags__tags',
                      tag: 'ReactTags__tag',
                      remove: 'ReactTags__remove',
                      tagInputField: 'ReactTags__tagInputField',
                      suggestions: 'ReactTags__suggestions',
                      activeSuggestion: 'ReactTags__suggestion--active',
                    }}
                  />
                  {/* Register custom amenities */}
                  <input
                    type="hidden"
                    value={JSON.stringify(customAmenities)}
                    {...register('customAmenities')}
                  />
                </div>
              </Col>
            </Row>

            <Form.Group className="mt-24px">
              <Form.Label className="editor-title-label">Property Logo</Form.Label>

              <Col sm={12}>
                <input
                  className="mt-16px"
                  onChange={handleLogoChange}
                  multiple={false}
                  ref={logoInputField}
                  type="file"
                  hidden
                />

                {logoFile ? (
                  <Image
                    alt=""
                    src={URL.createObjectURL(logoFile)}
                    width="75"
                    height="75"
                    className="ag-cell-img"
                  />
                ) : (
                  <Image
                    alt=""
                    src="/Icon-image.svg"
                    width="75"
                    height="75"
                    className="ag-cell-img"
                  />
                )}

                <Button
                  className="file-change-btn"
                  onClick={() => logoInputField.current?.click()}
                >
                  Add Logo{' '}
                </Button>
              </Col>
            </Form.Group>
            <Row className="mt-24px">
              <Form.Group className="mt-24">
                <Form.Label className="editor-title-label">Media Files</Form.Label>
                <Row>
                  <BasicFileUploader
                    files={files}
                    fileLs={fileLs}
                    handleDeleteFile={handleDeleteFile}
                    handleFileChange={handleFileChange}
                  />
                </Row>
              </Form.Group>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer className="mt-48px">
          <Button
            className="cancel-button"
            onClick={handleCloseModal}
            disabled={insertLoading || updateLoading}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            className="save-button"
            disabled={insertLoading || updateLoading}
          >
            {insertLoading || updateLoading ? 'Loading…' : 'Save'}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};
